<template>
  <div class="h-full flex flex-col">
    <div class="text-lg font-bold mx-5 mt-5 mb-2">
      {{ modalTitle.length > 0 ? modalTitle : meetingData_tmp.key === null ? $t('newMeeting') : $t('editMeeting') }}
    </div>

    <div class="m-5 mt-0 flex-1 flex flex-col gap-5 overflow-y-auto overflow-x-hidden" style="max-height: 650px">
      <div
        v-if="messageText && messageText.length > 0"
        class="flex gap-2 mt-2 text-base bg-gray-100 text-gray-700 rounded-md px-3 py-2.5"
      >
        <svg
          class="flex-shrink-0 fill-current h-5 w-5 text-gray-600"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"
          />
        </svg>
        {{ messageText }}
      </div>
      <div class="flex-shrink-0">
        <fw-label>{{ $t('meetingDate') }}</fw-label>
        <div class="flex gap-5">
          <TimeSelector
            :min-options="min => min % 5 === 0"
            :hour-options="hour => hour >= 8 && hour <= 19"
            :min="meetingData_tmp.min"
            :hour="meetingData_tmp.hour"
            @changed="selectMeetingTime"
          ></TimeSelector>
          <b-datepicker
            v-model="meetingData_tmp.date"
            append-to-body
            icon="calendar-alt"
            trap-focus
            :min-date="minDate"
            :placeholder="$t('chooseDate')"
            class="flex-1 w-full"
            locale="pt-PT"
            :unselectable-days-of-week="[0, 6]"
          ></b-datepicker>
        </div>
      </div>

      <div class="flex-shrink-0">
        <fw-label>{{ $t('meetingGoal') }}</fw-label>
        <div v-if="Array.isArray(subjectOptions) && subjectOptions.length === 1">{{ subjectOptions[0].text }}</div>
        <OptionsCard
          v-else-if="Array.isArray(subjectOptions) && subjectOptions.length > 1"
          :options="subjectOptions"
          :selected="meetingData_tmp.subject"
          @selected="selectMeetingSubject"
        ></OptionsCard>
        <TextInput v-else v-model="meetingData_tmp.subject"></TextInput>
      </div>

      <div v-if="typeOptions && typeOptions.length" class="flex-shrink-0">
        <fw-label>{{ $t('meetingType') }}</fw-label>
        <OptionsCard
          :options="typeOptions"
          :selected="meetingData_tmp.type"
          @selected="selectMeetingType"
        ></OptionsCard>
      </div>

      <div class="flex-shrink-0">
        <fw-label>{{ $t('meetingLocation') }}</fw-label>
        <TextInput
          v-if="meetingData_tmp.type !== 'online'"
          v-model="$v.meetingData_tmp.location.$model"
          :class="{
            error: $v.meetingData_tmp.location.$error,
          }"
          :placeholder="$t('meetingLocation')"
        ></TextInput>
        <fw-tip v-if="$v.meetingData_tmp.location.$error" error>{{ $t('enterMeetingLocation') }}</fw-tip>
        <div
          v-if="meetingData_tmp.type === 'online'"
          class="opacity-70 rounded-md border border-gray-100 shadow-md px-5 py-2 w-full text-left flex lg:text-md font-bold items-center"
        >
          <div class="flex-1">{{ $t('virtualMeeting') }}</div>
          <MeetingButton v-if="virtualMeeting !== null" :meeting="virtualMeeting" />
        </div>
      </div>

      <slot></slot>
      <slot v-if="showSendMessageForm" name="message"></slot>
    </div>
    <div class="flex items-center justify-end p-5 gap-5">
      <fw-button type="link-muted" @click.native="closeModal">
        {{ cancelText }}
      </fw-button>

      <fw-button :type="!confirmDisabled ? 'primary' : 'disabled'" :disabled="confirmDisabled" @click.native="save">
        {{ saveText }}
      </fw-button>
    </div>
  </div>
</template>

<script>
import MeetingButton from '../buttons/MeetingButton'
import Vue from 'vue'
import OptionsCard from '../form/OptionsCard'
import TextInput from '../form/TextInput'
import TimeSelector from '../form/TimeSelector'

import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'MeetingModal',
  components: { MeetingButton, OptionsCard, TextInput, TimeSelector },
  props: {
    messageText: {
      type: String,
      default: function() {
        return this.$t('save')
      },
    },
    saveText: {
      type: String,
      default: function() {
        return this.$t('save')
      },
    },
    cancelText: {
      type: String,
      default: function() {
        return this.$t('cancel')
      },
    },
    modalTitle: {
      type: String,
      default: '',
    },
    virtualMeeting: {
      type: Object,
      default: function() {
        return null
      },
    },
    showSendMessageForm: {
      type: Boolean,
      default: false,
    },
    confirmDisabled: {
      type: Boolean,
      default: false,
    },
    meetingData: {
      type: Object,
    },
    subjectOptions: {
      type: Array,
      default: function() {
        return []
      },
    },
    typeOptions: {
      type: Array,
      default: function() {
        return [
          {
            text: this.$t('onlineMeeting'),
            value: 'online',
          },
          {
            text: this.$t('inPersonMeeting'),
            value: 'presential',
          },
        ]
      },
    },
    minDate: {
      type: Date,
    },
  },

  data() {
    return {
      meetingData_tmp: {
        key: null,
        subject: '',
        type: 'online',
        date: new Date(),
        hour: null,
        min: null,
        duration: 0,
        location: null,
      },
    }
  },

  validations() {
    return {
      meetingData_tmp: {
        type: { required },
        subject: { required },
        date: { required },
        location: {
          required: requiredIf(function(nestedModel) {
            return nestedModel.type !== 'online'
          }),
        },
      },
    }
  },

  created() {
    if (this.meetingData) {
      this.meetingData_tmp = this.meetingData
    }
  },

  methods: {
    selectMeetingTime(time) {
      console.log('new meeting time: ' + time)
      let timeparts = time.split(':')
      Vue.set(this.meetingData_tmp, 'hour', timeparts[0])
      Vue.set(this.meetingData_tmp, 'min', timeparts[1])
    },

    selectMeetingSubject(subject) {
      Vue.set(this.meetingData_tmp, 'subject', subject)
    },

    selectMeetingType(type) {
      Vue.set(this.meetingData_tmp, 'type', type)
    },

    closeModal() {
      this.$emit('close')
    },

    save() {
      let meeting_datetime =
        this.meetingData_tmp.date.getFullYear() +
        '/' +
        (this.meetingData_tmp.date.getMonth() + 1) +
        '/' +
        this.meetingData_tmp.date.getDate() +
        ' ' +
        this.meetingData_tmp.hour +
        ':' +
        this.meetingData_tmp.min

      this.meetingData_tmp['meeting_datetime'] = meeting_datetime
      this.meetingData_tmp['text'] =
        typeof this.meetingData_tmp['subject'] === 'string'
          ? this.meetingData_tmp['subject']
          : this.meetingData_tmp['subject'].text

      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('save', this.meetingData_tmp)
      }
    },
  },
}
</script>

<style>
.datepicker .dropdown .dropdown-menu {
  z-index: 100 !important;
}
</style>

<i18n>
{
  "pt": {
    "editMeeting": "Editar reunião",
    "newMeeting": "Nova reunião",
    "meetingDate": "Hora e data da reunião",
    "chooseDate": "Escolha uma data",
    "meetingGoal": "Objetivo da reunião",
    "meetingType": "Tipo de reunião",
    "meetingLocation": "Local da reunião",
    "enterMeetingLocation": "Insira o local da reunião",
    "virtualMeeting": "Sala virtual",
    "save": "Guardar",
    "cancel": "Cancelar",
    "onlineMeeting": "Reunião online",
    "inPersonMeeting": "Reunião presencial"
  },
  "en": {
    "editMeeting": "Edit meeting",
    "newMeeting": "New meeting",
    "meetingDate": "Meeting time and date",
    "chooseDate": "Choose a date",
    "meetingGoal": "Meeting goal",
    "meetingType": "Meeting type",
    "meetingLocation": "Meeting location",
    "enterMeetingLocation": "Enter the meeting location",
    "virtualMeeting": "Virtual Meeting",
    "save": "Save",
    "cancel": "Cancel",
    "onlineMeeting": "Online meeting",
    "inPersonMeeting": "In-person meeting"
  }
}
</i18n>
