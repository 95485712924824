var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"px-2.5 rounded relative double-input",class:{ disabled: _vm.disabled === true }},_vm._l((_vm.innerInputs),function(inp,i){return _c('div',{key:'innerinput_' + i,staticClass:"relative"},[_c('label',{staticClass:"hidden",attrs:{"for":'double_input_' + i + _vm.$vnode.key}},[_vm._v(_vm._s(_vm.ariaLabels[inp.text]))]),(_vm.multiline === true)?_c('textarea',{ref:'textarea_' + i,refInFor:true,staticClass:"inp w-full p-1.5 text-lg",class:{ 'pl-10': _vm.innerInputs.length > 1, autogrow: _vm.autogrow === true },style:({ minHeight: _vm.exam ? '60vh' : _vm.minHeight > 0 ? _vm.minHeight + 'px' : 'auto' }),attrs:{"id":'double_input_' + i + _vm.$vnode.key,"placeholder":_vm.innerInputs.length > 0 && _vm.placeholder && _vm.placeholder[inp.text]
            ? _vm.placeholder[inp.text]
            : typeof _vm.placeholder == 'string'
            ? _vm.placeholder
            : '',"disabled":_vm.disabled === true,"rows":_vm.rows,"maxlength":_vm.maxlength == 0 ? 65000 : _vm.maxlength,"minlength":_vm.minlength},domProps:{"value":inp.value},on:{"click":function($event){return _vm.emptyInput()},"input":function($event){return _vm.changeInput(i, $event)},"change":function($event){return _vm.saveData()},"blur":function($event){return _vm.eventInput('blur')}}}):_c('input',{staticClass:"inp w-full pl-10 py-2",attrs:{"id":'double_input_' + i + _vm.$vnode.key,"placeholder":_vm.innerInputs.length > 0 && _vm.placeholder && _vm.placeholder[inp.text]
            ? _vm.placeholder[inp.text]
            : typeof _vm.placeholder == 'string'
            ? _vm.placeholder
            : '',"type":"text","maxlength":_vm.maxlength == 0 ? 65000 : _vm.maxlength,"minlength":_vm.minlength,"disabled":_vm.disabled === true},domProps:{"value":inp.value},on:{"click":function($event){return _vm.emptyInput()},"input":function($event){return _vm.changeInput(i, $event)},"blur":function($event){return _vm.eventInput('blur')},"change":function($event){return _vm.saveData()}}}),(inp.text.length > 0)?_c('div',{staticClass:"legend absolute px-1 py-0.5 bg-white uppercase rounded shadow leading-4 text-xs font-semibold top-2 left-1"},[_vm._v(" "+_vm._s(inp.text)+" ")]):_vm._e(),(i !== _vm.innerInputs.length - 1)?_c('div',{staticClass:"border-t border-gray-200"}):_vm._e()])}),0),(_vm.help)?_c('div',{staticClass:"text-xs text-gray-400 py-1"},[_vm._v(" "+_vm._s(_vm.help)+" ")]):_vm._e(),_c('div',{staticClass:"flex p-2 bg-white"},[(_vm.maxchars > 0)?_c('div',{staticClass:"text-left text-xs",class:{ 'font-bold text-red-600 ': _vm.exceedsMaximum }},[_vm._v(" "+_vm._s(_vm.currentCharacters)+"/"+_vm._s(_vm.maxchars)+" ")]):_vm._e(),_c('div',{staticClass:"flex-1"}),(_vm.showSaveButton)?_c('fw-button',{attrs:{"type":_vm.saving ? 'disabled' : 'primary',"size":_vm.tablet ? 'md' : 'sm'},nativeOn:{"click":function($event){return _vm.saveData(true)}}},[_vm._v(_vm._s(_vm.saving ? _vm.$t('saving') : _vm.$t('save')))]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }