<template>
  <fw-layout
    v-if="procedure"
    back-to="/manage/procedures"
    :loading="initialLoading"
    :loading-title="procedure.prefix + procedure.code"
  >
    <template #header-nav>
      <div class="flex gap-4 items-center">
        <div class="flex-shrink-0">
          <fw-heading size="lg">
            <span class="font-semibold text-gray-500">{{ procedure.prefix }}</span>
            <span class="font-bold">{{ procedure.code }}</span>
          </fw-heading>
        </div>
        <div>
          <fw-tag size="sm" :type="procedureStatusTagType" custom-class="px-3">
            <span v-if="!['draft', 'closed'].includes(procedure.status)" class="font-regular opacity-70 mr-1">{{
              $t('status')
            }}</span>
            {{ procedureStatus[procedure.status].label }}
          </fw-tag>
        </div>
      </div>
    </template>

    <template #header-toolbar>
      <HeaderProcedureActions
        v-if="isCurrentProcManager || canNotifyInterview"
        :procedure="procedure"
        :checks="{
          isCurrentProcManager: isCurrentProcManager,
          canOpen: canOpenProc,
          canNotifyInterview: canNotifyInterview,
          canPublish: canPublishProc,
          canDeleteProcedure: canDeleteProcedure,
          canLock: canLockProc,
          isResultsNotified: isResultsNotified,
          canClose: canCloseProc,
          notificationInterview: procedure.notifications['notification-interview'],
        }"
        :current-view-checks="currentViewChecks"
        @publish="publishProcedure"
        @notify-interview="sendInterviewNotification"
        @end="endProcedure"
        @close="closeProcedure"
        @open="openModal('jury_message')"
      />
      <UsersConnected v-if="connectedUsers.default.length" :users="connectedUsers.default" class="mx-5" />
    </template>

    <template #main-sidebar>
      <SidebarProcedure
        :procedure="procedure"
        :steps="steps"
        :procedure-steps="procedureSettingsSteps"
        :people-steps="peopleSettingsSteps"
        :is-current-proc-manager="isCurrentProcManager"
        :is-admin-or-manager="isAdmin || isManager"
        :checks="{
          canOpen: canOpenProc,
        }"
        :view="view"
        @go-to-view="goToView"
      />
    </template>

    <template v-if="procedure && !procedureLoading" #main-content>
      <PanelProcedureDashboard
        v-if="view === 'dashboard'"
        :procedure="procedure"
        :procedure-status="procedureStatus"
        :procedure-status-checks="procedureStatusChecks"
        :is-current-proc-manager="isCurrentProcManager"
        :my-jury-info="myJuryInfo"
        :can-delete-procedure="canDeleteProcedure"
        :can-edit-conflit-of-interest="canEditConflitOfInterest"
        @delete-procedure="deleteProcedure"
        @edit-no-conflict-of-interest="editNoConflictOfInterest"
      />
      <PanelProcedureMetadata
        v-if="view === 'metadata'"
        :procedure.sync="procedure"
        :professional-categories="professionalCategories"
        :faculties-and-departments="facultiesDepartments"
        :finance-sources="financeSources"
        :is-valid.sync="metadataIsValid"
        :can-edit="canEditMetadata"
        :disable-autowrite="disableTranslateTextAutowrite"
        :can-invite-people="canInvitePeople"
        :saving-data="savingData"
        @invite-person="invitePerson"
        @save-procedure="saveProcedure"
      />
      <PanelProcedureCriteria
        v-if="view === 'criteria'"
        :procedure.sync="procedure"
        :is-valid.sync="criteriaIsValid"
        :disable-autowrite="disableTranslateTextAutowrite"
        :can-edit="canEditCriteria"
        :is-scholarship="isScholarship"
        @save-procedure="saveProcedure"
      />
      <PanelProcedurePeople
        v-if="view === 'jury' || view === 'candidates' || view === 'settings'"
        :is-valid.sync="peopleIsValid"
        :can-change-secretariat="canEditManager"
        :procedure.sync="procedure"
        :can-edit="canEditPeople"
        :is-current-proc-manager="isCurrentProcManager"
        :saving-data="savingData"
        :can-invite-people="canInvitePeople"
        :view="view"
        :allow-download-candidates-zip="allowDownloadCandidatesZip"
        :downloading-files="downloadingFiles"
        @invite-person="invitePerson"
        @update-members="saveProcedureMembers"
        @update-manager="updateManager"
        @update-presidents="updatePresidents"
        @save-procedure="saveProcedure"
        @save-manager-notes="saveManagerNotes"
        @delete-manager-notes="deleteManagerNotes"
        @open-application="openApplication"
        @download-candidates="downloadCandidates"
      />
      <PanelProcedureCalendar
        v-if="view === 'calendar'"
        :is-valid.sync="calendarIsValid"
        :procedure.sync="procedure"
        :can-edit="canEditMetadata"
        :can-edit-homologation-date="canEditHomologationDate"
        :can-edit-meetings="canEditMeetings"
        :can-add-new-meeting="canAddNewMeeting"
        :saving-data="savingData"
        :loading-data="loadingData"
        :can-edit-interview-info="canEditInterviewInfo"
        :candidates="interviewCandidates"
        @save-procedure="saveProcedure"
        @new-meeting="newMeeting"
        @delete-meeting="deleteMeeting"
        @edit-meeting="editMeeting"
        @set-candidates-interview="setCandidatesInterview"
      />
      <PanelProceduresSummary v-if="view === 'summary'" :loading="loading" />
      <PanelProcedureActivity v-if="view === 'activity'" :procedure-key="procedureKey" />
      <PanelProcedureNotifications v-if="view === 'notifications'" :procedure-key="procedureKey" />
      <PanelProcedureUpdates
        v-if="view === 'updates'"
        :key="procedure.status"
        :procedure.sync="procedure"
        :can-edit="canEditUpdates"
        @delete-update="deleteUpdate"
        @get-procedure-data="updateProcedureData()"
      />
      <PanelProcedureComplaints
        v-if="view === 'complaints' && canAccessEvaluation"
        :procedure="procedure"
        :complaints="procedure.complaints"
        @open-application="openApplication($event, true)"
      />
      <PanelProcedureCurriculum
        v-if="view === 'seriation-curriculum' && canAccessEvaluation"
        :procedure.sync="procedure"
        :can-manage="isCurrentProcManager || isCurrentProcPresident"
        :my-jury-info="myJuryInfo"
        :is-scholarship="isScholarship"
        :current-view-checks.sync="currentViewChecks"
        :evaluation-maps="evaluationMaps"
        :can-edit="canEditCandidatesAdmission"
        :can-edit-jury-presences="canEditJuryPresences"
        :loading="loading"
        :can-send-interview-notification="canNotifyInterview"
        :allow-download-candidates-zip="allowDownloadCandidatesZip"
        :downloading-files="downloadingFiles"
        :candidates="currentViewCandidates"
        :interview-notification-sent="interviewNotificationSent"
        :is-current-proc-manager="isCurrentProcManager"
        @get-current-view-checks="loadcurrentViewChecks"
        @open-application="openApplication"
        @save-meeting="saveStepMeeting"
        @start-meeting="startMeeting"
        @end-meeting="endMeeting"
        @close-meeting="closeMeeting"
        @reopen-meeting="reopenMeeting"
        @set-user-presence="setUserPresence"
        @open-evaluation-map="openEvaluationMap"
        @open-rejected-reason="openRejectedReason"
        @admit-user="setCandidatesAdmission"
        @admit-bulk-users="setBulkCandidatesAdmission"
        @download-minute="downloadMinute"
        @download-candidates="downloadCandidates"
        @create-interview-meeting="createInterviewMeeting"
      />
      <PanelProcedureInterview
        v-else-if="view === 'seriation-interview' && canAccessEvaluation"
        :procedure.sync="procedure"
        :can-manage="isCurrentProcManager || isCurrentProcPresident"
        :is-current-proc-manager="isCurrentProcManager"
        :my-jury-info="myJuryInfo"
        :is-scholarship="isScholarship"
        :current-view-checks.sync="currentViewChecks"
        :evaluation-maps="evaluationMaps"
        :can-edit-jury-presences="canEditJuryPresences"
        :can-edit-candidates-presences="canEditCandidatesPresences"
        :loading="loading"
        :can-send-interview-notification="canNotifyInterview"
        :allow-download-candidates-zip="allowDownloadCandidatesZip"
        :downloading-files="downloadingFiles"
        :can-download-presence-file="canDownloadPresenceFile"
        :candidates="currentViewCandidates"
        :interview-notification-sent="interviewNotificationSent"
        @get-current-view-checks="loadcurrentViewChecks"
        @open-application="openApplication"
        @save-meeting="saveStepMeeting"
        @start-meeting="startMeeting"
        @end-meeting="endMeeting"
        @close-meeting="closeMeeting"
        @reopen-meeting="reopenMeeting"
        @set-user-presence="setUserPresence"
        @set-candidates-presence="setCandidatePresence"
        @open-evaluation-map="openEvaluationMap"
        @open-rejected-reason="openRejectedReason"
        @admit-user="setCandidatesAdmission"
        @download-minute="downloadMinute"
        @download-candidates="downloadCandidates"
        @download-presence-file="downloadCandidatePresenceFile"
        @send-interview-notification="sendInterviewNotification"
        @set-candidates-interview="setCandidatesInterview"
        @create-interview-meeting="createInterviewMeeting"
      />
      <PanelProcedureFinal
        v-if="view === 'final' && canAccessEvaluation"
        :results="finalResults"
        :can-show-final-results="canShowFinalResults"
        :can-manage="isCurrentProcManager || isCurrentProcPresident"
        :loading="loading"
        @open-application="openApplication"
      />
      <SeriationResults
        v-else-if="
          (view === 'seriation-curriculum' || view === 'seriation-interview') &&
            currentViewChecks.meeting_started &&
            canAccessEvaluation &&
            !loading
        "
        :view="viewName"
        :title="$t('seriationResults')"
        :can-manage="isCurrentProcManager || isCurrentProcPresident"
        :all-candidates-rejected="allCandidatesRejected"
        :current-view-checks="currentViewChecks"
        :results="seriationResults"
        :can-show-results="canShowFinalResults"
        :loading="loading"
        class="pb-10"
        @run-seriation="runSeriation"
        @undo-seriation="undoSeriation"
        @reject-candidates="rejectCandidates"
        @open-application="openApplication"
      />
      <div v-if="isEvaluationView && hasJuryConflitOfInterest" class="h-full flex items-center justify-center">
        <div class="flex flex-col item-center justify-center max-w-xl">
          <fw-heading size="h2" class="text-center">{{ $t('accessNotAllowed') }}</fw-heading>
          <div class="text-center mt-3">
            <div class="text-gray-500 font-medium text-lg">{{ $t('hasConflitofInterestAccessNotAllowed') }}</div>
            <div class="text-sm text-gray-500 my-3">
              Até à primeira reunião de avaliação, pode alterar a sua resposta a partir da secção "Declarações"
              existente na página de Dashboard deste procedimento.
            </div>
            <a
              href=""
              class="text-primary text-sm font-medium"
              @click="$router.push(`/manage/procedure/${procedure.key}/dashboard`)"
            >
              Aceder ao dashboard</a
            >
          </div>
        </div>
      </div>
    </template>

    <template #modals>
      <b-modal
        :active="activeModal === 'conflictOfInterest' || activeModal === 'meetings'"
        scroll="keep"
        :can-cancel="true"
        trap-focus
        auto-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :on-cancel="closeModal"
        :width="700"
        custom-class="rounded-buefy-modal"
      >
        <ProcedureMeetingModal
          v-if="activeModal === 'meetings'"
          :meeting-types="meetingTypes"
          :meeting-goal-override="meetingGoalOverride"
          :meeting-data="meetingData"
          :meeting-modal-text="meetingModalText"
          :virtual-meeting-data="virtualMeetingData"
          :available-meeting-goals="availableMeetingGoals"
          :can-notify-calendar="canNotifyCalendar"
          @save="saveMeeting"
          @close="closeModal"
        />

        <ConflitOfInterestModal
          v-else-if="activeModal === 'conflictOfInterest'"
          :procedure="procedure"
          :is-current-proc-manager="isCurrentProcManager"
          :my-jury-info="myJuryInfo"
          :can-edit="canEditConflitOfInterest"
          @edit-no-conflict-of-interest="editNoConflictOfInterest"
          @close="closeModal"
        />
      </b-modal>

      <b-modal
        :active="activeModal === 'evaluations' || activeModal === 'jury_message' || activeModal === 'application'"
        scroll="keep"
        :can-cancel="true"
        trap-focus
        auto-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :on-cancel="closeModal"
        custom-class="rounded-buefy-modal"
      >
        <EmailEditor
          v-if="activeModal === 'jury_message'"
          :default-template="defaultTemplate"
          :procedure-full-code="procedureFullCode"
          :template-editor-variables="templateEditorVariables"
          :confirm-button-text="$t('sendAndStartProcedure')"
          @close="closeModal"
          @save="openProcedure"
        />

        <ApplicationModal
          v-else-if="activeModal === 'application'"
          :key="selectedApplication.candidate.key"
          :application.sync="selectedApplication"
          :saving-data.sync="savingData"
          :is-current-proc-manager="isCurrentProcManager"
          :can-edit="canEditEvaluation"
          :can-edit-application-notes="
            canEditApplicationNotes && selectedApplication.candidate.application_status == 'submitted'
          "
          :curriculum-criteria-list="procedure.curriculum_criteria"
          :interview-criteria-list="procedure.interview_criteria"
          :jury-list="juryFullList"
          :show-next-application="procedure.applications && procedure.applications.length > 1"
          :can-edit-interview-info="canEditInterviewInfo"
          :can-edit-candidates-admission="canEditCandidatesAdmission"
          :can-edit-candidates-presences="canEditCandidatesPresences"
          :with-interview-panel="showApplicationInterviewPanel"
          :with-curriculum-panel="showApplicationCurriculumPanel"
          :can-respond-complaint="canRespondComplaint"
          @admit-user="setCandidatesAdmission"
          @open-rejected-reason="openRejectedReason"
          @delete-manager-notes="deleteManagerNotes"
          @save-manager-notes="saveManagerNotes"
          @next-application="openNextApplication"
          @set-candidates-interview="setCandidatesInterview"
          @set-interview-presence="setCandidatePresence"
          @add-complaint-message="addComplaintMessage"
          @close="closeModal"
        />

        <EvaluationMap
          v-else-if="activeModal === 'evaluations'"
          :evaluation-map="selectedJuryMap"
          :jury="selectedJury"
          :procedure-key="procedureKey"
          :criteria-list="criteriaList"
          :candidates="forEvaluationCandidates"
          :present-candidates-keys="presentCandidatesKeys"
          :editable="canEditEvaluationMap"
          :current-view-checks="currentViewChecks"
          language="pt"
          :loading="savingData"
          :view="viewName"
          :can-toggle-map="isCurrentProcManager || isCurrentProcPresident"
          @open-application="openApplication"
          @save="saveEvaluations"
          @lock-map="lockEvalMap"
          @open-map="openEvalMap"
          @close="closeModal"
        ></EvaluationMap>
      </b-modal>

      <b-modal
        :active="rejectedApplication !== null"
        scroll="keep"
        :can-cancel="true"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        auto-focus
        :width="700"
        :on-cancel="closeRejectedReasonModal"
        custom-class="rounded-buefy-modal"
      >
        <RejectedReasonModal
          v-if="rejectedApplication"
          :editable="canEditEvaluation"
          :application="rejectedApplication"
          :saving-data="savingData"
          @close="closeRejectedReasonModal"
          @save="saveRejectReason"
        />
      </b-modal>
    </template>
  </fw-layout>
</template>

<script>
import PanelProcedureDashboard from '@/components/panels/PanelProcedureDashboard'
import PanelProcedureActivity from '@/components/panels/PanelProcedureActivity'
import PanelProcedureNotifications from '@/components/panels/PanelProcedureNotifications'
import PanelProcedurePeople from '@/components/panels/PanelProcedurePeople'
import PanelProcedureMetadata from '@/components/panels/PanelProcedureMetadata'
import PanelProcedureCalendar from '@/components/panels/PanelProcedureCalendar'
import PanelProcedureCriteria from '@/components/panels/PanelProcedureCriteria'
import PanelProcedureUpdates from '@/components/panels/PanelProcedureUpdates'
import ConflitOfInterestModal from '@/components/modals/ConflitOfInterestModal'

import PanelProcedureCurriculum from '@/components/panels/PanelProcedureCurriculum'
import PanelProcedureInterview from '@/components/panels/PanelProcedureInterview'
import PanelProcedureComplaints from '@/components/panels/PanelProcedureComplaints'
import PanelProcedureFinal from '@/components/panels/PanelProcedureFinal'
import HeaderProcedureActions from '@/components/header/HeaderProcedureActions'
import SeriationResults from '@/components/panels/SeriationResults'
import SidebarProcedure from '@/components/sidebars/SidebarProcedure'
import EmailEditor from '@/components/modals/EmailEditor'
import EvaluationMap from '@/components/modals/EvaluationMap'
import ApplicationModal from '@/components/modals/ApplicationModal'
import ProcedureMeetingModal from '@/components/modals/ProcedureMeetingModal'
import RejectedReasonModal from '@/components/modals/RejectedReasonModal'
import UsersConnected from '@/fw-modules/fw-core-vue/ui/components/users/UsersConnected'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  name: 'Procedure',

  components: {
    PanelProcedureDashboard,
    PanelProcedureActivity,
    PanelProcedureNotifications,
    PanelProcedurePeople,
    PanelProcedureMetadata,
    PanelProcedureCalendar,
    PanelProcedureCriteria,
    PanelProcedureUpdates,
    PanelProcedureCurriculum,
    PanelProcedureInterview,
    PanelProcedureFinal,
    HeaderProcedureActions,
    SidebarProcedure,
    EvaluationMap,
    EmailEditor,
    ApplicationModal,
    ProcedureMeetingModal,
    SeriationResults,
    RejectedReasonModal,
    PanelProcedureComplaints,
    UsersConnected,
    ConflitOfInterestModal,
  },

  data() {
    return {
      mapSearchQuery: '', //to search inside a map

      interviewIsSet: false,

      viewOverride: null,
      loading: true,
      downloadingFiles: false,
      procedureLoading: true,
      savingData: false,
      loadingData: {
        setCandidatesInterview: false,
      },
      initialLoading: true,
      activeModal: null,

      // Procedure
      procedure: null,
      validations: null,

      metadataIsValid: false,
      criteriaIsValid: false,
      calendarIsValid: false,
      peopleIsValid: false,

      // Metadata
      facultiesDepartments: [],
      professionalCategories: [],
      categories: null,
      financeSources: [],

      // Meetings
      meetingGoalOverride: null,
      meetingGoals: [
        {
          text: this.$t('meetingGoals.admission'),
          value: 'admission',
        },
        {
          text: this.$t('meetingGoals.seriation-curriculum'),
          value: 'seriation-curriculum',
        },
        {
          text: this.$t('meetingGoals.seriation-interview'),
          value: 'seriation-interview',
        },
        {
          text: this.$t('meetingGoals.complaints'),
          value: 'complaints',
        },
      ],
      meetingData: {
        key: null,
        goal: null,
        subject: null,
        type: 'online',
        date: null,
        hour: null,
        min: null,
        location: '',
        uc_meetings_id: 'proc123',
      },

      // Evaluation
      evaluationMaps: {},
      selectedJury: null,
      currentViewChecks: {
        step: '',
        is_readonly: false,
        join_is_readonly: false,
        can_start_meeting: false,
        can_end_meeting: false,
        can_undo_meeting_end: false,
        can_end_attendance: false,
        can_end_merit: false,
        can_cancel_seriation: false,
        can_show_seriation: false,
        can_run_seriation: false,
        can_close_seriation_step: false,
        is_seriation_ended: false,
        meeting_closed: false,
        meeting_ended: false,
        meeting_started: false,
        jury: [],
      },
      seriationResults: [],
      finalResults: {},
      complaints: [],

      // ApplicationModal
      applicationKey: null,
      selectedApplication: null, // application prop for ApplicationModal

      // RejectedReasonModal
      rejectedApplication: null,

      defaultTemplate: {},

      procSubscription: null,
    }
  },

  computed: {
    // Forms
    disableTranslateTextAutowrite() {
      return true
    },

    // Basic info
    view() {
      console.log('viewOverride :>> ', this.viewOverride)
      if (this.viewOverride !== null) {
        return this.viewOverride
      } else {
        return this.procedureSubpage
      }
    },

    connectedUsers() {
      return this.$store.getters.getConnectedUsers || { default: null }
    },

    api() {
      return this.$store.state.api.base
    },

    me() {
      return this.$store.state.session && this.$store.state.session.user ? this.$store.state.session.user : null
    },

    isMobile() {
      return window.innerWidth <= 640
    },

    locale() {
      return this.$store.state.locales
    },

    viewName() {
      return this.view.replace('seriation-', '')
    },

    dirtyData() {
      return this.$store.state.dirtyData
    },

    // Procedure info
    isScholarship() {
      return this.procedure !== null && this.procedure.type == 'scholarships'
    },

    procedureKey() {
      return this.$route.params.key ? this.$route.params.key : ''
    },

    procedureFullCode() {
      return this.procedure ? this.procedure.prefix + this.procedure.code : ''
    },

    procedureSubpage() {
      return this.$route.params.subpage ? this.$route.params.subpage.toLowerCase() : 'dashboard'
    },

    procedureTitle() {
      return this.procedure ? this.procedureFullCode.toUpperCase() : 'Sem código'
    },

    procedurePageTitle() {
      if (this.view === 'metadata' || this.view === 'criteria' || this.view === 'criteria' || this.view === 'jury') {
        return this.$t('procedure')
      } else if (this.view === 'updates') {
        return this.$t('updates')
      } else if (this.view === 'calendar') {
        return this.$t('calendar')
      } else if (this.view === 'candidates') {
        return this.$t('candidates')
      } else if (this.view === 'complaints') {
        return this.$t('complaints')
      } else {
        return ''
      }
    },

    procedureStatus() {
      return {
        draft: {
          label: this.$t('procedureStatus.draft'),
          canChange: false,
          isActive: this.procedure.status == 'draft',
          isDone: this.procedure.status != 'draft',
        },
        published: {
          label: this.$t('procedureStatus.published'),
          canChange: this.canPublishProc,
          isActive: this.procedure.status == 'published',
          isDone: this.procedure.is_published,
        },
        jury: {
          label: this.$t('procedureStatus.jury'),
          canChange: this.canOpenProc,
          isActive: this.procedure.status == 'jury' || this.procedure.status == 'ended',
          isDone: this.procedure.status == 'ended',
        },
        ended: {
          label: this.$t('procedureStatus.ended'),
          canChange: this.canCloseProc,
          isActive: this.procedure.status == 'ended',
          isDone: this.procedure.status == 'closed',
        },
        closed: {
          label: this.$t('procedureStatus.closed'),
          canChange: this.canLockProc,
          isActive: this.procedure.status == 'closed',
          isDone: this.procedure.status == 'closed',
        },
      }
    },

    procedureStatusChecks() {
      let response = {
        draft: [
          {
            valid: this.validations.metadata,
            text: this.$t('procedureStatusChecks.metadata'),
          },
          {
            valid: this.validations.criteria,
            text: this.$t('procedureStatusChecks.criteria'),
          },
          {
            valid: this.validations.people,
            text: this.$t('procedureStatusChecks.people'),
          },
          {
            valid: this.validations.calendar,
            text: this.$t('procedureStatusChecks.calendar'),
          },
          {
            valid: this.validations.has_notice_update,
            text: this.$t('procedureStatusChecks.update'),
          },
        ],
        published: [
          {
            valid: this.procedure.publish_ended,
            text: this.$t('procedureStatusChecks.publishEnded'),
          },
        ],
        jury: [
          {
            valid: this.procedure.validations.curriculum,
            text: this.$t('procedureStatusChecks.curriculum'),
          },
        ],
        ended: [
          {
            valid: this.procedure.validations.can_complain,
            text: this.$t('procedureStatusChecks.maxDateComplaints'),
          },
        ],
      }

      if (this.procedure.with_interview) {
        response.jury.push(
          {
            valid: this.procedure.validations.interview,
            text: this.$t('procedureStatusChecks.interview'),
          },
          {
            valid: this.validations.has_interview_update,
            text: this.$t('procedureStatusChecks.interviewUpdate'),
          }
        )
      } else {
        response.jury.push({
          valid: this.validations.has_interview_update,
          text: this.$t('procedureStatusChecks.curriculumUpdate'),
        })
      }

      if (this.procedure.complaints_info.total > 0) {
        response.ended.push({
          valid: this.procedure.complaints_info.unsolved == 0,
          text: this.$t('procedureStatusChecks.allSolved'),
        })
      }
      return response
    },

    procedureStatusTagType() {
      var type = 'medium'
      if (this.procedure.status == 'draft') {
        type = 'orange'
      } else if (['jury', 'published'].includes(this.procedure.status)) {
        type = 'primary'
      }
      return type
    },

    peopleSettingsSteps() {
      return [
        {
          value: 'jury',
          text: this.$t('jury'),
          status: this.validations && this.validations.people ? 'finished' : 'next',
        },
        {
          value: 'candidates',
          text: this.$t('candidates'),
          status: 'finished',
        },
        {
          value: 'settings',
          text: this.$t('manager'),
          status: 'finished',
        },
      ]
    },

    procedureSettingsSteps() {
      return [
        {
          text: this.$t('metadata'),
          value: 'metadata', //used for router and vue key
          icon: '',
          status: this.validations && this.validations.metadata ? 'finished' : 'next',
          meeting_datetime: null,
        },
        {
          text: this.$t('criteria'),
          value: 'criteria', //used for router and vue key
          meeting_datetime: null, //no caso se ser necessário uma reunião para discutir critérios
          icon: '',
          status: this.validations && this.validations.criteria ? 'finished' : 'next',
        },
      ]
    },

    steps() {
      let steps = []
      let myJuryCan = true
      if (this.myJuryInfo) {
        myJuryCan = this.myJuryInfo.no_conflict_of_interest
      }
      if (!this.isScholarship) {
        steps.push({
          text: this.$t('meetingGoals.admission'),
          value: 'admission',
          status: myJuryCan ? (this.validations && this.validations.admission ? 'finished' : 'next') : 'locked',
        })
      }

      steps.push({
        text: this.$t('meetingGoals.seriation-curriculum'),
        value: 'seriation-curriculum',
        status: myJuryCan ? (this.validations && this.validations.curriculum ? 'finished' : 'next') : 'locked',
      })

      if (this.procedure.with_interview) {
        steps.push({
          text:
            this.procedure.type === 'scholarships'
              ? this.$t('meetingGoals.seriation-interview')
              : this.$t('meetingGoals.publicHearing'),
          value: 'seriation-interview',
          status: myJuryCan ? (this.validations && this.validations.interview ? 'finished' : 'next') : 'locked',
        })
      }

      steps.push({
        text: this.$t('result'),
        value: 'final',
        status: myJuryCan ? (this.canShowFinalResults ? 'finished' : 'next') : 'locked',
      })

      if (this.isScholarship) {
        steps.push({
          text: this.$t('complaints'),
          value: 'complaints',
          status: myJuryCan
            ? this.procedure.status === 'ended'
              ? 'next'
              : this.procedure.status === 'closed'
              ? 'finished'
              : 'next'
            : 'locked',
        })
      }

      return steps
    },

    // Start procedure Notification
    templateEditorVariables() {
      const validKeys = {
        '{NAME}': this.$t('variables.name'),
        '{CODE}': this.$t('variables.code'),
        '{SEATS}': this.$t('variables.seats'),
        '{TYPE}': this.$t('variables.type'),
        '{LINK}': this.$t('variables.link'),
        '{MANAGER_NAME}': this.$t('variables.managerName'),
        '{MANAGER_EMAIL}': this.$t('variables.managerEmail'),
        '{END_DATE}': this.$t('variables.closingApplications'),
      }

      if (this.isScholarship) {
        validKeys['{PROJECT_NAME}'] = this.$t('variables.projectName')
        validKeys['{PROFILE}'] = this.$t('variables.profile')
        validKeys['{SCIENTIFIC_AREA}'] = this.$t('variables.scientificArea')
      } else {
        validKeys['{CATEGORY}'] = this.$t('variables.category')
        validKeys['{AREA}'] = this.$t('variables.area')
        validKeys['{FACULTY}'] = this.$t('variables.faculty')
      }

      return validKeys
    },

    // Notifications
    managerUpdateNotificationSent() {
      return Boolean(this.procedure.notifications['notification-manager-update'])
    },

    publishedNotificationSent() {
      return Boolean(this.procedure.notifications['notification-publish'])
    },

    startNotificationSent() {
      return Boolean(this.procedure.notifications['notification-start'])
    },

    resultsNotificationSent() {
      return (
        Boolean(this.procedure.notifications['notification-results-final']) ||
        Boolean(this.procedure.notifications['notification-results-missed-interview'])
      )
    },

    rejectedNotificationSent() {
      return Boolean(this.procedure.notifications['notification-results-exclude'])
    },

    interviewNotificationSent() {
      return Boolean(this.procedure.notifications['notification-interview'])
    },

    noCandidatesNotificationSent() {
      return Boolean(this.procedure.notifications['notification-no-candidates'])
    },

    isResultsNotified() {
      return Boolean(this.resultsNotificationSent || !this.validations.has_candidates_to_notify)
    },

    // Candidates
    currentViewCandidates() {
      if (this.viewName == 'curriculum') {
        if (this.procedure.applications) {
          return this.procedure.applications.filter(
            el =>
              el.rejected_step !== 'interview' &&
              el.application_status !== 'cancelled' &&
              el.admission_status === 'selected'
          )
        } else {
          return []
        }
      } else {
        return this.interviewCandidates
      }
    },

    interviewCandidates() {
      let applications = []
      if (this.view === 'calendar' && this.seriationResults) {
        applications = this.seriationResults
      } else {
        applications = this.procedure.applications
      }

      if (applications && applications.length) {
        applications = applications.filter(
          el =>
            el.rejected_step != 'curriculum' &&
            el.rejected_step != 'admission' &&
            el.application_status !== 'cancelled' &&
            el.admission_status === 'selected'
        )
      }

      return applications
    },

    myJuryInfo() {
      return this.procedure.my_jury_info
    },

    allowDownloadCandidatesZip() {
      return this.procedure.publish_ended
    },

    presentCandidatesKeys() {
      if (this.procedure && this.procedure.applications) {
        if (this.viewName == 'curriculum') {
          return this.procedure.applications
            .filter(el => el.admission_status === 'selected' && el.application_status !== 'cancelled')
            .map(el => el.key)
        } else {
          return this.procedure.applications
            .filter(el => el.attendance_status === 'attending' && el.application_status !== 'cancelled')
            .map(el => el.key)
        }
      } else {
        return []
      }
    },

    readOnlyStep() {
      return this.currentViewChecks && this.currentViewChecks.is_readonly
    },

    selectedJuryMap() {
      return this.evaluationMaps && this.selectedJury ? this.evaluationMaps[this.selectedJury.jury_key] : null
    },

    criteriaList() {
      if (this.procedure && this.view === 'seriation-curriculum') {
        return this.procedure.curriculum_criteria
      } else if (this.procedure && this.view === 'seriation-interview') {
        return this.procedure.interview_criteria
      } else {
        return []
      }
    },

    juryFullList() {
      return this.procedure.vogals.concat(this.procedure.presidents)
    },

    candidatesPresencesMap() {
      if (this.procedure.applications) {
        return this.procedure.applications.filter(
          el =>
            el.admission_status === 'selected' &&
            el.rejected_step !== 'curriculum' &&
            el.application_status !== 'cancelled'
        )
      }

      return []
    },

    forEvaluationCandidates() {
      if (this.view == 'seriation-curriculum' && this.procedure.applications) {
        return this.procedure.applications.filter(
          el => el.application_status === 'submitted' && el.admission_status != 'rejected'
        )
      }

      if (this.view == 'seriation-interview' && this.procedure.applications) {
        return this.procedure.applications.filter(
          el =>
            el.admission_status == 'selected' &&
            el.rejected_step !== 'curriculum' &&
            el.application_status !== 'cancelled' &&
            el.attendance_status !== 'missing'
        )
      }

      return []
    },

    isEvaluationView() {
      return Boolean(
        ['seriation-curriculum', 'seriation-interview', 'final', 'complaints'].includes(this.view) &&
          this.steps &&
          this.steps.length
      )
    },

    // Permissions
    canDeleteProcedure() {
      return this.isCurrentProcManager && this.validations && this.validations.can_remove
    },

    canEditMetadata() {
      return this.isCurrentProcManager && (this.procedure.status === 'draft' || this.procedure.status === 'published')
    },

    canEditHomologationDate() {
      return this.isCurrentProcManager && this.procedure.status != 'closed'
    },

    canEditManager() {
      return this.validations && this.validations.can_change_manager
    },

    canEditPeople() {
      return this.isCurrentProcManager && this.validations && this.validations.can_edit_juries
    },

    canEditUpdates() {
      return this.isCurrentProcManager && this.procedure.status !== 'closed'
    },

    canEditApplicationNotes() {
      return (
        this.isCurrentProcManager &&
        (this.procedure.status == 'jury' || this.validations.can_open) &&
        !this.procedure.admission_started
      )
    },

    canEditCriteria() {
      return this.canEditMetadata
    },

    canInvitePeople() {
      return this.isProcedureManager || this.isManager || this.isAdmin
    },

    canNotifyInterview() {
      return (
        this.validations &&
        this.validations.can_notify_interview &&
        (!this.validations.has_rejected_candidates || this.rejectedNotificationSent)
      )
    },

    allCandidatesRejected() {
      if (this.procedure) {
        let applications = this.procedure.applications || []

        if (applications.length == 0) {
          return false
        }

        if (this.view == 'admission') {
          let list = applications.filter(el => el.application_status === 'submitted' && !el.rejected_step)
          return !list.length > 0
        }

        if (this.view == 'seriation-curriculum') {
          let list = applications.filter(
            el => el.application_status === 'submitted' && el.rejected_step !== 'admission'
          )
          return !list.length > 0
        }

        if (this.view == 'seriation-interview') {
          let list = applications.filter(
            el => el.application_status === 'submitted' && el.rejected_step !== 'curriculum'
          )
          return !list.length > 0
        }

        let list = applications.filter(
          el => el.application_status === 'submitted' && !el.rejected_step && el.attendance_status === 'attending'
        )
        return !list.length > 0
      }

      return true
    },

    canManage() {
      return this.isCurrentProcManager
    },

    canEditCandidatesPresences() {
      return (
        this.currentViewChecks &&
        !this.validations?.interview &&
        this.currentViewChecks.meeting_started &&
        !this.currentViewChecks.meeting_ended &&
        !this.currentViewChecks.is_seriation_ended &&
        !Object.values(this.evaluationMaps).find(el => el[`${this.viewName}_status`] === 'closed') &&
        (this.isCurrentProcManager || this.isJury)
      )
    },

    canEditCandidatesAdmission() {
      return (
        this.currentViewChecks &&
        !this.validations?.curriculum &&
        this.currentViewChecks.meeting_started &&
        !this.currentViewChecks.meeting_ended &&
        !Object.values(this.evaluationMaps).find(el => el[`${this.viewName}_status`] === 'closed') &&
        (this.isCurrentProcManager || this.isJury)
      )
    },

    canPublishProc() {
      return this.validations && this.validations.can_publish
    },

    canOpenProc() {
      return this.validations && this.validations.can_open
    },

    canNotifyCalendar() {
      return this.procedure.status === 'jury'
    },

    canCloseProc() {
      return this.validations && this.validations.can_close
    },

    canLockProc() {
      return this.validations && this.validations.can_lock
    },

    canEditJuryPresences() {
      return this.procedure.status == 'jury' && !this.currentViewChecks.join_is_readonly
    },

    canEditConflitOfInterest() {
      return Boolean(this.myJuryInfo) && !this.procedure.admission_started
    },

    canRespondComplaint() {
      return this.validations && this.validations.can_complain
    },

    canEditEvaluation() {
      return (
        this.procedure.status == 'jury' &&
        !this.currentViewChecks.meeting_ended &&
        this.currentViewChecks.meeting_started &&
        (this.isCurrentProcManager ||
          (this.me && this.selectedJuryMap && this.selectedJury.key == this.me.key) ||
          (this.myJuryInfo && this.myJuryInfo.no_conflict_of_interest))
      )
    },

    canEditEvaluationMap() {
      return (
        this.canEditEvaluation && this.selectedJuryMap && this.selectedJuryMap[`${this.viewName}_status`] != 'closed'
      )
    },

    canAccessEvaluation() {
      // Check if user can see evaluation phase
      return (
        this.isCurrentProcManager ||
        this.isAdmin ||
        this.isManager ||
        (this.myJuryInfo && this.myJuryInfo.no_conflict_of_interest)
      )
    },

    hasJuryConflitOfInterest() {
      return this.myJuryInfo && !this.myJuryInfo.no_conflict_of_interest
    },

    canShowFinalResults() {
      return this.procedure.validations.can_show_final_results
    },

    canEditInterviewInfo() {
      return this.validations && this.validations.can_set_candidates_interview
    },

    showApplicationInterviewPanel() {
      return (
        this.procedure.status != 'draft' &&
        this.procedure.status != 'published' &&
        this.validations &&
        this.validations.curriculum &&
        this.procedure.with_interview
      )
    },

    showApplicationCurriculumPanel() {
      return this.procedure.status != 'draft' && this.procedure.status != 'published'
    },

    // Meetings
    meetingTypes() {
      if (this.showVirtualMeetings) {
        return [
          {
            text: this.$t('meetingTypes.online'),
            value: 'online',
          },
          {
            text: this.$t('meetingTypes.in_person'),
            value: 'presential',
          },
        ]
      }

      return []
    },

    activeMeetings() {
      return this.procedure && this.procedure.meetings ? this.procedure.meetings.filter(el => !el['_destroy']) : []
    },

    canEditMeetings() {
      return (this.isCurrentProcManager || this.isCurrentProcPresident) && this.procedure.status != 'closed'
    },

    canDownloadPresenceFile() {
      return this.currentViewChecks && this.currentViewChecks.meeting_closed
    },

    canAddNewMeeting() {
      return (
        (this.isCurrentProcManager || this.isCurrentProcPresident) &&
        this.availableMeetingGoals.length > 0 &&
        this.procedure.status != 'closed'
      )
    },

    meetingModalText() {
      if (this.meetingGoalOverride === 'meeting-now') {
        return this.$t('verifyMeetingLocation')
      }

      return ''
    },

    availableMeetingGoals() {
      if (this.meetingGoalOverride === 'interview_notification' || this.meetingGoalOverride === 'seriation-interview') {
        return [this.meetingGoals.find(el => el.value == 'seriation-interview')]
      }

      if (this.view == 'seriation-curriculum' || this.view == 'seriation-interview') {
        return [this.meetingGoals.find(el => el.value == this.view)]
      }

      let meetingGoals = this.meetingGoals
      if (!this.procedure.with_interview) {
        meetingGoals = this.meetingGoals.filter(el => el.value != 'seriation-interview')
      }

      if (this.activeMeetings.length) {
        const currentTypes = this.activeMeetings.map(el => el.goal)

        if (currentTypes.length) {
          return meetingGoals.filter(
            el =>
              this.isScholarship &&
              el.value != 'admission' &&
              (!currentTypes.includes(el.value) || this.meetingData.goal == el.value)
          )
        }
      }

      return meetingGoals.filter(el => this.isScholarship && el.value != 'admission')
    },

    virtualMeetingData() {
      return {
        key_alias: 'proc_123',
      }
    },

    showVirtualMeetings() {
      return this.$store.state.showVirtualMeetings
    },

    // Roles
    userRoles() {
      return this.$store.getters.userPermissions
    },

    isProcedureManager() {
      return this.userRoles.isProcedureManager
    },

    isAdmin() {
      return this.userRoles.isAdmin
    },

    isManager() {
      return this.userRoles.isManager
    },

    isJury() {
      return this.userRoles.isJury
    },

    isCurrentProcManager() {
      return this.procedure && this.me && this.procedure.manager.key == this.me.key
    },

    isCurrentProcPresident() {
      return Boolean(this.procedure && this.procedure.my_jury_info?.jury_type === 'president')
    },
  },

  watch: {
    async $route() {
      await this.getProcedureData()
      if (this.view == 'metadata') {
        this.getSettingsData()
      }

      const isEvaluationView = Boolean(
        ['seriation-curriculum', 'seriation-interview', 'final', 'complaints'].includes(this.view) &&
          this.steps &&
          this.steps.length
      )

      if (isEvaluationView && this.myJuryInfo && this.myJuryInfo.no_conflict_of_interest === null) {
        this.openModal('conflictOfInterest')
      } else {
        this.$nextTick(() => {
          this.seriationResults = []
          this.getCurrentStepData()
        })
      }
    },
  },

  async mounted() {
    this.setDirty(false)
    await this.getProcedureData()

    // Check if user is trying to access evaluation view without accepting the no_conflict_of_interest clause
    if (
      ['seriation-curriculum', 'seriation-interview', 'final', 'complaints'].includes(this.view) &&
      this.myJuryInfo &&
      !this.myJuryInfo.no_conflict_of_interest
    ) {
      this.$router.push({ path: `/manage/procedure/${this.procedureKey}/dashboard` })
    }

    if (this.view == 'metadata') {
      this.getSettingsData()
    }

    await this.getCurrentStepData()

    // Local stuff only
    this.saveLocalLastProcedure()
  },

  beforeDestroy() {
    if (this.procSubscription) {
      this.procSubscription.destroy()
      window.removeEventListener('beforeunload', this.beforeUnloadAction)
      this.procSubscription = null
    }
  },

  methods: {
    saveLocalLastProcedure() {
      const proc = {
        code: this.procedure.code,
        key: this.procedure.key,
        prefix: this.procedure.prefix,
      }
      localStorage.setItem('last-procedure-open', JSON.stringify(proc))
    },

    async editNoConflictOfInterest(accept) {
      try {
        const res = await this.api.editJury(this.myJuryInfo.jury_key, { no_conflict_of_interest: accept })
        this.myJuryInfo.no_conflict_of_interest = res
        console.log('editNoConflictOfInterest: ', res)
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        console.error(error)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.default.title'),
          type: 'is-danger',
          duration: 4000,
        })
        console.error(error)
      }
    },

    async invitePerson(users, roles = null, type = 'add') {
      console.log('type :>> ', type)
      if (type == 'invite' && (!roles || !roles.length)) {
        roles = ['jury']
      }

      try {
        if (type == 'add') {
          const newUsers = await this.api.addUser({ users })
          console.log('createdUsers ==> ', newUsers)
          newUsers.forEach(element => {
            this.$buefy.snackbar.open({
              message: this.$t('accountAdded', { user: element.full_name, email: element.email }),
              type: 'is-primary',
              position: 'is-top-right',
              duration: 2000,
              queue: true,
            })
          })
        } else if (type == 'invite') {
          let user = users[0]
          const invitedUser = await this.api.inviteUser({
            email: user.email,
            name: user.name || user.display_name || user.full_name,
            roles: roles,
            no_email: false,
          })
          console.log('inviteUser ==>', invitedUser)
          this.$buefy.snackbar.open({
            message: this.$t('accountAdded', { user: invitedUser.full_name, email: invitedUser.email }),
            type: 'is-primary',
            position: 'is-top-right',
            duration: 2000,
            queue: true,
          })
        }
      } catch (error) {
        const errorKey = utils.errors(error).getKey()
        console.log('Error KEY', errorKey)
        if (errorKey && errorKey == 'UserAlreadyExists') {
          this.$buefy.dialog.alert({
            title: this.$t('errorOccurred.userExist.title'),
            message: this.$t('errorOccurred.userExist.message'),
            type: 'is-danger',
          })
        } else {
          this.$buefy.dialog.alert({
            title: this.$t('errorOccurred.default.title'),
            message: this.$t('errorOccurred.default.message', { errorKey: errorKey ? `(${errorKey})` : '' }),
            type: 'is-danger',
          })
        }

        console.error(error)
      }
      this.closeModal()
    },

    async getSettingsData() {
      this.loading = true
      try {
        console.log('getSettingsData =====')
        const result = await this.api.getSettings()
        console.log(result)
        this.categories = result.categories[this.procedure.type]
        this.professionalCategories = result.professional_categories[this.procedure.type]
        this.facultiesDepartments = Object.values(result.faculties_and_departments)
        this.financeSources = result.finance_sources
        this.defaultTemplate = result.procedure_started_template_scholarships
      } catch (e) {
        console.error(e)
      }

      this.loading = false
    },

    setProcedureData(procedure) {
      if (!procedure) return

      console.log('setProcedureData ==> ', procedure)

      if (procedure.published_dr_date) {
        procedure.published_dr_date = Dates.build(procedure.published_dr_date).toDate()
      }
      if (procedure.publish_start) {
        procedure.publish_start = Dates.build(procedure.publish_start).toDate()
      }
      if (procedure.publish_end) {
        procedure.publish_end = Dates.build(procedure.publish_end).toDate()
      }
      if (procedure.homologation_date) {
        procedure.homologation_date = Dates.build(procedure.homologation_date).toDate()
      }

      // Fix dates (time) to make sure we got the correct tz
      procedure.meetings = procedure.meetings
        ? procedure.meetings.map(el => {
            return {
              ...el,
              date: new Date(el.date),
            }
          })
        : []

      this.procedure = procedure

      this.setProcedureValidations(procedure)

      this.setProcedureApplications(procedure.applications)

      //autoopen next step
      this.autoOpenNextStep()
    },

    setProcedureApplications(applications) {
      if (!applications) return
      applications.forEach(element => {
        if (element.interview_datetime) {
          element.interview_datetime = new Date(element.interview_datetime)
        }
      })

      this.procedure.applications = applications
    },

    autoOpenNextStep() {
      let autoNextStep = null
      //Auto open current step data
      if (this.procedureSubpage === '') {
        let next = this.steps.filter(el => el.status === 'next')
        if (next.length > 0) {
          autoNextStep = next[0].value
        }

        if (autoNextStep == null) {
          next = this.procedureSettingsSteps.filter(el => el.status === 'next')
          if (next.length > 0) {
            autoNextStep = next[0].value
          }
        }
        if (autoNextStep !== null) {
          this.viewOverride = autoNextStep
        } else {
          this.viewOverride = 'metadata'
        }
      }
    },

    downloadFile(data, filename) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    },

    async downloadCandidates(option) {
      if (this.allowDownloadCandidatesZip) {
        this.downloadingFiles = true
        try {
          if (option == 'files') {
            const response = await ServiceStorage.getZipFile(
              'apply',
              this.procedureKey,
              this.$store.state.session.user.token,
              'candidates'
            )
            this.downloadFile(
              response,
              `${this.$t('candidates')}-${this.procedureFullCode}-${this.$t('files').toLowerCase()}.zip`
            )
          } else {
            const response = await this.api.getCandidatesZip(
              this.procedureKey,
              `${this.$t('candidates')}-${this.procedureFullCode}.zip`,
              option
            )
            this.downloadFile(response, `candidates-${this.procedureFullCode}.zip`)
          }
        } catch (error) {
          console.error(error)
          this.$buefy.snackbar.open({
            message: this.$t('errorOccurred.download'),
            type: 'is-danger',
            duration: 4000,
          })
        }

        this.downloadingFiles = false
      }
    },

    async downloadCandidatePresenceFile(candidate) {
      this.downloadingFiles = true
      try {
        const response = await this.api.getCandidatePresenceFile(
          candidate.candidate_key,
          `candidate-${candidate.full_name}-${this.procedureFullCode}.docx`
        )
        this.downloadFile(response, `candidate-${candidate.full_name}-${this.procedureFullCode}.docx`)
      } catch (error) {
        console.error(error)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.download'),
          type: 'is-danger',
          duration: 4000,
        })
      }

      this.downloadingFiles = false
    },

    deleteProcedure() {
      this.$buefy.dialog.confirm({
        confirmText: 'Eliminar',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: 'Eliminar procedimento',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja eliminar este procedimento?
        Todos os dados serão eliminados e não será possível recuperar qualquer informação.</div>`,
        onConfirm: () => {
          this.$buefy.dialog.prompt({
            confirmText: 'Eliminar',
            type: 'is-danger',
            cancelText: 'Cancelar',
            message: `Para confirmar que deseja eliminar o procedimento, por favor,
                escreva "confirmo" na caixa seguinte e carregue no botão "Eliminar" novamente.`,
            closeOnConfirm: false,
            inputAttrs: {
              placeholder: 'Escreva "confirmo"',
              maxlength: 8,
            },
            trapFocus: true,
            onConfirm: (value, { close }) => {
              if (value.toLowerCase() == 'confirmo') {
                this.doReallyDeleteProcedure()
                close()
              }
            },
          })
        },
      })
    },

    async doReallyDeleteProcedure() {
      if (!this.canDeleteProcedure) return // Backend will keep us save but... just in case...
      try {
        await this.api.deleteProcedure(this.procedureKey)
        this.$buefy.snackbar.open({
          message: `Procedimento <b>${this.procedureFullCode}</b> eliminado`,
          type: 'is-primary',
          position: 'is-top-right',
          duration: 2000,
          queue: true,
        })
        this.$router.push({
          name: 'manage',
        })
      } catch (error) {
        const examKey = this.exam ? this.exam.key : null
        console.error(`Deleting procedure ${examKey}`, error)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.deletingProcedure'),
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false,
        })
      }
    },

    getProcSections() {
      let sections = ['validations']
      if (this.view.startsWith('seriation-')) {
        sections.push('people', 'criteria', 'calendar')
      } else if (
        this.view == 'final' ||
        this.view === 'jury' ||
        this.view === 'candidates' ||
        this.view === 'settings'
      ) {
        sections.push('people', 'criteria')
      } else if (this.view == 'complaints') {
        sections.push('criteria', this.view)
      } else {
        sections.push(this.view)
      }

      return sections
    },

    async updateProcedureData() {
      try {
        const result = await this.api.getProcedureByKey(this.procedureKey, this.getProcSections())
        this.setProcedureData(result)
        console.log('getProcedureData', result)
      } catch (error) {
        const errorKey = utils.errors(error).getKey()
        console.log('Error KEY', errorKey)

        if (errorKey && errorKey === 'NotFound') {
          return this.$router.push({ name: 'error' })
        }

        console.error(error)
      }
    },

    async getProcedureData() {
      this.procedureLoading = true

      try {
        const result = await this.api.getProcedureByKey(this.procedureKey, this.getProcSections())
        this.setProcedureData(result)
        console.log('getProcedureData', result)

        if (!this.procSubscription) {
          this.procSubscription = this.api.createProcSubscription(this.procedureKey, 'default')
          window.addEventListener('beforeunload', this.beforeUnloadAction, { capture: true })
          console.log(`Procedure subscription (${this.procedureKey}-default)`, this.procSubscription)
        }
      } catch (error) {
        const errorKey = utils.errors(error).getKey()
        console.log('Error KEY', errorKey)

        if (errorKey && errorKey === 'NotFound') {
          return this.$router.push({ name: 'error' })
        }

        console.error(error)
      }

      this.procedureLoading = false

      setTimeout(() => {
        this.initialLoading = false
      }, 500)
    },

    beforeUnloadAction() {
      //event
      //event.preventDefault()
      console.log('beforeUnloadAction')
      if (this.procSubscription) {
        this.procSubscription.destroy()
      }
    },

    async publishProcedure() {
      console.log('publishProcedure')
      if (!this.canPublishProc) return

      this.$buefy.dialog.confirm({
        cancelText: this.$t('cancel'),
        confirmText: this.$t('publish'),
        message: `Ao publicar o procedimento, vamos também, notificar a abertura das candidaturas ao presidente e aos vogais effetivos.
            Se os membros do Júri forem posteriormente alterados, não receberão as notificações.
            Tem a certeza que quer publicar o procedimento?`,
        onConfirm: async () => {
          this.doPublishProcedure()
        },
      })
    },

    async doPublishProcedure() {
      if (!this.canPublishProc) return

      let result
      try {
        result = await this.api.publishProcedure(this.procedureKey)
        this.setProcedureValidations(result)
        console.log('after publish', result)
      } catch (error) {
        console.error(error)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.publishingProcedure'),
          type: 'is-danger',
          duration: 4000,
        })
      }
    },

    async endProcedure() {
      console.log('End evaluation phase')
      if (!this.canCloseProc) return

      this.$buefy.dialog.confirm({
        cancelText: this.$t('cancel'),
        confirmText: this.$t('confirm'),
        message: `Ao <b>terminar a fase avaliativa</b> do procedimento, os candidatos admitidos serão notificados da
        publicação dos resultados e será iniciado período de audiência de interessados. Tem a certeza que quer terminar?`,
        onConfirm: async () => {
          this.doEndProcedure()
        },
      })
    },

    async doEndProcedure() {
      if (!this.canCloseProc) return

      let result
      try {
        result = await this.api.endProcedure(this.procedureKey)
        this.setProcedureValidations(result)
      } catch (error) {
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.endingProcedure'),
          type: 'is-danger',
          duration: 4000,
        })
        console.error(error)
      }
    },

    async closeProcedure() {
      console.log('Lock procedure')
      if (!this.canLockProc) return

      if (this.validations && !this.validations.has_submitted_candidates) {
        this.$buefy.dialog.confirm({
          cancelText: this.$t('cancel'),
          confirmText: this.$t('confirm'),
          message: `Ao <b>Encerrar</b> o procedimento, vamos também, notificar
            os membros do Júri de que o procedimento não teve candidaturas.
            Tem a certeza que quer terminar?`,
          onConfirm: async () => {
            this.doCloseProcedure()
          },
        })
      } else {
        this.$buefy.dialog.confirm({
          cancelText: this.$t('cancel'),
          confirmText: this.$t('confirm'),
          message: `Tem a certeza que quer o <b>Encerrar</b> o procedimento?`,
          onConfirm: async () => {
            this.doCloseProcedure()
          },
        })
      }
    },

    async doCloseProcedure() {
      if (!this.canLockProc) return

      let result
      try {
        result = await this.api.closeProcedure(this.procedureKey)
        console.log('result :>> ', result)
        this.setProcedureValidations(result)
      } catch (error) {
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.closingProcedure'),
          type: 'is-danger',
          duration: 4000,
        })
        console.error(error)
      }
    },

    async openProcedure(payload) {
      if (!this.canOpenProc) return

      this.$buefy.dialog.confirm({
        cancelText: this.$t('cancel'),
        confirmText: this.$t('confirm'),
        title: `Tem a certeza que deseja avançar?`,
        message: `Este passo irá enviar a notificação que preparou ao presidente de júri
          e vogais efetivos.`,
        onConfirm: async () => {
          this.doOpenProcedure(payload)
        },
      })
    },

    async doOpenProcedure(payload) {
      if (!this.canOpenProc) return

      let result
      try {
        this.closeModal()
        result = await this.api.openProcedure(this.procedureKey, payload)
        this.setProcedureValidations(result)
        this.$buefy.snackbar.open({
          message: this.$t('procedureWasBeenOpenToTheJury'),
          type: 'is-success',
        })
      } catch (error) {
        console.error(error)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.openingProcedure'),
          type: 'is-danger',
          duration: 4000,
        })
      }
    },

    async setCandidatesAdmission(candidateKey, value) {
      console.log('setCandidatesAdmission =======> ', candidateKey, value)
      this.savingData = true
      const application = this.procedure.applications.find(el => el.candidate_key === candidateKey)
      try {
        const res = await this.api.setCandidatesAdmission(this.procedureKey, candidateKey, value)
        this.currentViewChecks = res.checks
        this.validations = res.procedure.validations
        this.procedure.notifications = res.procedure.notifications
        console.log('setCandidatesAdmission res', res)
        application.rejected = value
        application.admission_status = res.candidate.admission_status

        if (this.selectedApplication) {
          this.selectedApplication.candidate.admission_status = res.candidate.admission_status
          this.selectedApplication.candidate.rejected_reason = res.candidate.rejected_reason
          this.selectedApplication.candidate.rejected_step = res.candidate.rejected_step
        }
        this.setDirty(false)
      } catch (error) {
        console.error(error)
        this.setDirty(true)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.setingCandidatesAdmission'),
          type: 'is-danger',
          duration: 4000,
        })
      }
      this.savingData = false
    },

    async setBulkCandidatesAdmission(candidatesKeys, value) {
      console.log('setBulkCandidatesAdmission =======> ', candidatesKeys, value)
      this.savingData = true
      try {
        const res = await this.api.setBulkCandidatesAdmission(this.procedureKey, candidatesKeys, value)
        this.currentViewChecks = res.checks
        this.validations = res.procedure.validations
        this.procedure.notifications = res.procedure.notifications
        console.log('setBulkCandidatesAdmission res', res)
        this.$set(this.procedure, 'applications', res.candidates)
        this.setDirty(false)
      } catch (error) {
        console.error(error)
        this.setDirty(true)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.setingCandidatesAdmission'),
          type: 'is-danger',
          duration: 4000,
        })
      }
      this.savingData = false
    },

    async saveRejectReason(applicationKey, justification) {
      console.log('saveRejectReason =======> ', applicationKey, justification)
      this.savingData = true
      const application = this.procedure.applications.find(el => el.candidate_key === applicationKey)

      try {
        const res = await this.api.setCandidatesAdmission(this.procedureKey, applicationKey, null, justification)
        this.currentViewChecks = res.checks
        application.rejected_step = this.viewName
        application.rejected_reason = justification
        if (this.selectedApplication) {
          this.selectedApplication.candidate.admission_status = res.candidate.admission_status
          this.selectedApplication.candidate.rejected_reason = res.candidate.rejected_reason
          this.selectedApplication.candidate.rejected_step = res.candidate.rejected_step
        }

        console.log(res)
        this.setDirty(false)
      } catch (error) {
        console.error(error)
        this.setDirty(true)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.savingRejectReason'),
          type: 'is-danger',
          duration: 4000,
        })
      }
      this.savingData = false
    },

    async getApplication(applicationKey) {
      try {
        const application = await this.api.getCandidateApplication(applicationKey, true, true) // Get complaints and evaluations
        console.log('getApplication :>> ', application)
        if (application.candidate.interview_datetime) {
          application.candidate.interview_datetime = new Date(application.candidate.interview_datetime) // because of buefy datetime picker
        }
        this.selectedApplication = application
      } catch (error) {
        console.error(error)
      }
    },

    openCandidateEvaluation(candidateID) {
      console.log('openCandidateEvaluation', candidateID)
      this.openCandidate = candidateID
    },

    openNextApplication(currentApplicationKey) {
      console.log('currentApplicationKey', currentApplicationKey)
      const currentIndex = this.procedure.applications.findIndex(el => el.candidate_key == currentApplicationKey)
      const nextIndex = (currentIndex + 1) % this.procedure.applications.length
      console.log('Open next nextIndex', nextIndex)
      console.log('Open next application', this.procedure.applications[nextIndex])
      this.openApplication(this.procedure.applications[nextIndex].candidate_key)
    },

    async openApplication(candidateKey, loadJury = false) {
      console.log('openApplication', candidateKey)
      if (loadJury) {
        const response = await Promise.all([
          await this.api.getProcedureByKey(this.procedureKey, ['people']),
          await this.getApplication(candidateKey, loadJury),
        ])
        this.procedure.presidents = response[0].presidents
        this.procedure.vogals = response[0].vogals
      } else {
        await this.getApplication(candidateKey, loadJury)
      }
      this.activeModal = 'application'
    },

    openRejectedReason(application) {
      console.log('openRejectedReason: ', application)
      this.rejectedApplication = application
    },

    setDirty(value = true) {
      this.$store.commit('setDirtyData', value)
    },

    debounce(func, timeout = 300) {
      if (this.debounceTimer !== null) {
        clearTimeout(this.debounceTimer)
      }

      this.debounceTimer = setTimeout(() => {
        func.apply()
      }, timeout)
    },

    async saveProcedure(dataToSave) {
      console.log('Save procedure data', dataToSave)
      this.savingData = true
      let result = null

      try {
        result = await this.api.updateProcedure(this.procedureKey, dataToSave, this.getProcSections())
        console.log('Updated procedure', result)
        this.setProcedureData(result)
        this.setProcedureValidations(result)

        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        const errorKey = utils.errors(error).getKey()
        console.log('Error KEY', errorKey)
        let title, message
        if (errorKey && errorKey === 'CodeAlreadyExists') {
          title = this.$t('errorOccurred.codeExists.title')
          message = this.$t('errorOccurred.codeExists.message')
        } else {
          title = this.$t('errorOccurred.default.title')
          message = this.$t('errorOccurred.savingProcedure', { errorKey: errorKey ? `(${errorKey})` : '' })
        }
        this.$buefy.dialog.alert({
          title: title,
          message: message,
          type: 'is-danger',
          duration: 4000,
        })
      }

      this.savingData = false
      return result
    },

    async saveManagerNotes(candidateKey, dataToSave) {
      console.log('Save manager notes data', candidateKey, dataToSave)
      this.savingData = true
      let result = {}

      try {
        result = await this.api.saveManagerReview(candidateKey, dataToSave)
        console.log('Saved manager notes', result)
        const candidate = this.procedure.applications.find(el => el.candidate_key == result.candidate_key)
        if (candidate) {
          candidate.manager_admission_review = result.manager_admission_review
          candidate.manager_id = result.manager_id
          candidate.manager_notes = result.manager_notes
        }
        this.getCurrentStepData()
        this.setDirty(false)
      } catch (error) {
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.savingManagerNotes'),
          type: 'is-danger',
          duration: 4000,
        })
        this.setDirty(true)
      }

      this.savingData = false
      return result
    },

    async deleteManagerNotes(candidateKey) {
      console.log('Delete manager notes for candidate', candidateKey)
      this.savingData = true
      let result = {}

      try {
        await this.api.deleteManagerReview(candidateKey)
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.deletingManagerNotes'),
          type: 'is-danger',
          duration: 4000,
        })
      }

      this.savingData = false
      return result
    },

    async deleteUpdate(update) {
      try {
        await this.api.deleteProcedureUpdate(this.procedureKey, update.key)
      } catch (e) {
        console.error(e)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.deletingUpdate'),
          type: 'is-danger',
          duration: 4000,
        })
      }
    },

    async saveProcedureMembers(dataToSave) {
      console.log('Save procedure members data', dataToSave)
      this.savingData = true
      let result = {}

      try {
        result = await this.api.updateMembers(this.procedureKey, dataToSave)
        this.procedure.vogals = result.vogals
        this.validations = result.validations
        console.log('Updated procedure', result)
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        const errorKey = utils.errors(error).getKey()
        console.log('Error KEY', errorKey)
        console.log('UPDATE ERROR', error)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.savingProcedure', { errorKey: errorKey ? `(${errorKey})` : '' }),
          type: 'is-danger',
          duration: 4000,
        })
      }

      this.savingData = false
      return result
    },

    async sendInterviewNotification() {
      console.log('sendInterviewNotification ===>')

      this.$buefy.dialog.confirm({
        cancelText: this.$t('cancel'),
        confirmText: this.$t('send'),
        message: 'Tem a certeza que deseja enviar as convocatórias para entrevista a todos os candidatos?',
        onConfirm: async () => {
          try {
            const res = await this.api.sendInterviewEmails(this.procedureKey)
            this.procedure.notifications = res.procedure.notifications
            this.validations = res.procedure.validations
            this.currentViewChecks = res.procedure.interview
            this.$buefy.snackbar.open({
              message: this.$t('notificationsSent.interviewNotification'),
              type: 'is-success',
            })
          } catch (e) {
            console.error(e)
            this.$buefy.snackbar.open({
              message: this.$t('errorOccurred.sendingInterviewNotification'),
              type: 'is-danger',
              duration: 4000,
            })
          }
        },
      })
    },

    async getSeriationData(type) {
      if (!['curriculum', 'interview', 'admission'].includes(type)) {
        return
      }
      console.log('getSeriationData ===>')
      this.loading = true

      try {
        this.currentViewChecks = {}
        this.seriationResults = []
        const seriation = await this.api.getSeriation(this.procedureKey, type)
        this.currentViewChecks = seriation.checks
        this.seriationResults = seriation.positions
        console.log('seriation', seriation)
      } catch (e) {
        console.error(e)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.gettingSeriation'),
          type: 'is-danger',
          duration: 4000,
        })
      }

      this.loading = false
    },

    async loadcurrentViewChecks(type) {
      if (!['curriculum', 'interview', 'admission'].includes(type)) {
        return
      }

      this.loading = true
      if (!type) {
        type = this.viewName
      }

      try {
        this.currentViewChecks = {}
        const info = await this.api.getStepsInfo(this.procedureKey, type)
        console.log('loadcurrentViewChecks', info)
        this.currentViewChecks = info
      } catch (e) {
        console.error(e)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.gettingStepInfo'),
          type: 'is-danger',
          duration: 4000,
        })
      }

      this.loading = false
    },

    async getCurrentStepData() {
      console.log('getCurrentStepData ==== ', this.view)

      if (this.view == 'calendar' && this.procedure.with_interview && this.validations?.curriculum) {
        this.getSeriationData('curriculum')
      }

      if (this.view === 'final' && this.canShowFinalResults) {
        const results = await this.api.getFinalResults(this.procedureKey)
        console.log('getFinalResults--------', results)
        this.finalResults = results
        return true
      }

      if (['seriation-curriculum', 'seriation-interview', 'admission'].includes(this.view)) {
        if (this.view != 'admission') {
          await this.getJuryInfo()
        }

        await this.loadcurrentViewChecks(this.viewName)

        if (this.currentViewChecks.can_show_seriation) {
          this.getSeriationData(this.viewName)
        }
      }
    },

    /**
     * Meetings
     */
    newMeeting() {
      let goal = null
      console.log('newMeeting availableMeetingGoals', this.availableMeetingGoals)
      if (this.availableMeetingGoals.length === 1) {
        goal = this.availableMeetingGoals[0].value
      }
      this.meetingData = {
        key: null,
        goal: goal,
        subject: goal,
        type: this.showVirtualMeetings ? 'online' : 'in_person',
        date: new Date(),
        hour: null,
        min: null,
        duration: 0,
        location: this.procedure.location ? this.procedure.location : null,
        uc_meetings_id: this.showVirtualMeetings ? 'proc123' : null,
      }

      console.log('NEW MEETING', this.meetingData)
      this.activeModal = 'meetings'
    },

    resetMeetingData() {
      this.meetingData = {
        key: null,
        goal: null,
        subject: null,
        type: this.showVirtualMeetings ? 'online' : 'in_person',
        date: null,
        hour: null,
        min: null,
        location: '',
        uc_meetings_id: this.showVirtualMeetings ? 'proc123' : null,
      }
    },

    async saveMeeting(data, notifyJury = false, message = null) {
      console.log('SAVE MEETING DATA ', data, notifyJury)
      let updatedMeetings = []
      let meetingDateTime = Dates.formatToAPI(data.meeting_datetime)

      let meetingLocation = data.type === 'in_person' ? data.location : null
      if (data.key) {
        let currMeeting = this.procedure.meetings.find(meeting => meeting.key === data.key)
        currMeeting.date = meetingDateTime
        currMeeting.goal = data.subject
        currMeeting.subject = data.subject
        currMeeting.type = data.type
        currMeeting.duration = data.duration ? data.duration : 0
        currMeeting.location = meetingLocation
        currMeeting.uc_meetings_id = data.type == 'in_person' ? null : data.uc_meetings_id
        updatedMeetings = this.procedure.meetings
      } else {
        updatedMeetings = [
          {
            date: meetingDateTime,
            goal: data.subject,
            subject: data.subject,
            type: data.type,
            duration: data.duration ? data.duration : 0,
            location: meetingLocation,
            uc_meetings_id: data.type == 'in_person' ? null : data.uc_meetings_id,
          },
          ...this.procedure.meetings,
        ]
      }

      console.log('updatedMeetings :>> ', updatedMeetings)

      const proc = await this.saveProcedure({ meetings: updatedMeetings })
      if (notifyJury && proc) {
        await this.sendNotificationCalendar(data.subject, message)
      }

      if (this.view.startsWith('seriation-') && !this.currentViewChecks.meeting_started) {
        this.startMeeting()
      }
      this.closeModal()
    },

    async sendNotificationCalendar(meetingGoal, message = null) {
      try {
        await this.api.sendNotifyCalendar(this.procedureKey, meetingGoal, message)
        this.setDirty(false)
        this.$buefy.snackbar.open({
          message: this.$t('notificationsSent.calendar'),
          type: 'is-success',
        })
      } catch (error) {
        this.setDirty(true)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.sendingCalendarUpdate'),
          type: 'is-danger',
          duration: 4000,
        })
        console.error(error)
      }
    },

    deleteMeeting(pos) {
      let meeting = this.procedure.meetings[pos]
      if (meeting.key) {
        meeting['_destroy'] = true
        this.$set(this.procedure.meetings, pos, meeting)
      } else {
        this.procedure.meetings.splice(pos, 1)
      }

      this.saveProcedure({ meetings: this.procedure.meetings })
      this.resetMeetingData()
    },

    editMeeting(pos) {
      const meetingData = this.procedure.meetings[pos]
      console.log('EDIT MEETING DATA', pos, meetingData)
      let defaultLocation = this.procedure.location ? this.procedure.location : null
      let datetime = null,
        date = null,
        hour = null,
        minute = null

      if (meetingData.date) {
        datetime = Dates.buildCore(meetingData.date)
        date = datetime.toDate()
        hour = datetime.get('hour').toString()
        minute = datetime.get('minute').toString()
      }

      this.meetingData = {
        key: meetingData.key,
        goal: meetingData.goal,
        subject: meetingData.goal,
        type: meetingData.type,
        date: date,
        hour: hour,
        min: minute,
        duration: meetingData.duration,
        location: meetingData.location ? meetingData.location : defaultLocation,
        uc_meetings_id: meetingData.uc_meetings_id,
      }

      console.log('this.meetingData :>> ', this.meetingData)

      this.activeModal = 'meetings'
    },

    async updateMeetingDate(meeting) {
      meeting.date = new Date()
      console.log('meeting.date :>> ', meeting.date)
      await this.saveProcedure({ meetings: this.procedure.meetings })
    },

    saveStepMeeting(view, meeting = null) {
      this.meetingGoalOverride = view
      if (meeting) {
        // Update meeting time to now
        this.$buefy.dialog.confirm({
          cancelText: this.$t('cancel'),
          confirmText: this.$t('openMeeting'),
          message: this.$t('continueOpenMeeting', { date: Dates.formatCore(meeting.date) }),
          onConfirm: async () => {
            await this.updateMeetingDate(meeting)
            await this.startMeeting()
          },
        })
      } else {
        console.log('create now meeting')
        this.newMeeting()
      }
    },

    createInterviewMeeting() {
      let defaultLocation = this.procedure.location ? this.procedure.location : null
      this.meetingData = {
        key: null,
        subject: 'seriation-interview',
        type: this.$store.state.showVirtualMeetings ? 'online' : 'in_person',
        date: Dates.now().toDate(),
        hour: null,
        min: null,
        duration: 0,
        location: defaultLocation,
        uc_meetings_id: this.$store.state.showVirtualMeetings ? 'proc123' : null,
      }
      this.meetingGoalOverride = 'seriation-interview'
      this.activeModal = 'meetings'
    },

    setProcedureValidations(info) {
      if (info.notifications) this.procedure.notifications = info.notifications
      if (info.complaints) this.procedure.complaints

      this.validations = info.validations
      this.procedure.status = info.status
      this.procedure.admission_started = info.admission_started
      this.procedure.curriculum_closed = info.curriculum_closed
      this.procedure.interview_started = info.interview_started
      this.procedure.is_published = info.is_published
      this.procedure.open_at = info.open_at
      this.procedure.can_show_final_results = info.can_show_final_results
      this.procedure.with_interview = info.with_interview
      this.procedure.publish_ended = info.publish_ended
    },

    async startMeeting() {
      console.log('===== startMeeting')
      let step = this.view == 'seriation-curriculum' && this.isScholarship ? 'admission' : this.viewName
      try {
        const res = await this.api.startMeeting(this.procedureKey, step)
        this.currentViewChecks = res.checks
        this.setProcedureValidations(res.procedure)

        if (this.view != 'admission') {
          await this.getJuryInfo()
        }
        console.log(res)
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.startingMeeting'),
          type: 'is-danger',
          duration: 4000,
        })
        console.error(error)
      }
    },

    async endMeeting() {
      /*
      validations:Object
        admission:"2022-02-17T18:53:27"
        curriculum:null
        interview:null
       */
      console.log('===== endMeeting')
      let step = this.view == 'seriation-curriculum' && this.isScholarship ? 'admission' : this.viewName
      try {
        const res = await this.api.endMeeting(this.procedureKey, step, true)
        this.currentViewChecks = res.checks
        this.setProcedureValidations(res.procedure)
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.endingMeeting'),
          type: 'is-danger',
          duration: 4000,
        })
        console.error(error)
      }
    },

    async reopenMeeting() {
      console.log('===== reopenMeeting')
      if (this.currentViewChecks.is_seriation_ended) {
        console.log('undoSeriation')
        await this.undoSeriation()
        this.seriationResults = []
      }
      let step = this.view == 'seriation-curriculum' && this.isScholarship ? 'admission' : this.viewName
      console.log('undoEndMeeting for step ==>', step)
      try {
        const res = await this.api.undoEndMeeting(this.procedureKey, step)
        this.currentViewChecks = res.checks
        this.setProcedureValidations(res.procedure)
        console.log(res)
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.reopeningMeeting'),
          type: 'is-danger',
          duration: 4000,
        })
        console.error(error)
      }
    },

    async closeMeeting() {
      this.doCloseMeeting()
    },

    async doCloseMeeting() {
      try {
        const res = await this.api.closeMeeting(this.procedureKey, this.viewName)
        console.log('===== closeMeeting', res)
        this.currentViewChecks = res.checks
        this.setProcedureValidations(res.procedure)
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.closingMeeting'),
          type: 'is-danger',
          duration: 4000,
        })
        console.error(error)
      }
    },

    async setUserPresence(personKey, option) {
      let value = option === 0
      let step = this.view == 'seriation-curriculum' && this.isScholarship ? 'admission' : this.viewName

      console.log(`setUserPresence as ${value} for user ${personKey} in step ${this.viewName}`)
      try {
        const res = await this.api.setMeetingPresences(this.procedureKey, personKey, step, value)
        console.log('setUserPresence', res)
        this.currentViewChecks = res.validations
        this.evaluationMaps = res.jury
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.settingPresences'),
          type: 'is-danger',
          duration: 4000,
        })
        console.error(error)
      }
    },

    async setCandidatePresence(personKey, option) {
      let value = option === 0

      const reqData = {
        language: 'pt',
        candidate: personKey,
        attendance: value ? 'attending' : 'missing',
        admitted: false,
        withAttendance: false,
        forInterview: false,
      }

      console.log(`setCandidatePresence as ${value} for user ${personKey} in step ${this.viewName}`)
      try {
        const res = await this.api.setCandidatesAttendance(this.procedureKey, reqData)
        console.log('setCandidatePresence', res)
        this.currentViewChecks = res.checks
        this.setProcedureValidations(res.procedure)

        const candidateIndex = this.procedure.applications.findIndex(el => el.candidate_key == personKey)
        console.log('candidateIndex', candidateIndex)
        this.$set(this.procedure.applications, candidateIndex, res.candidate)

        if (this.selectedApplication) {
          this.selectedApplication.candidate.attendance_status = res.candidate.attendance_status
        }
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        console.error(error)
      }
    },

    // Download ata da reuniao
    async downloadMinute(minuteLanguage) {
      console.log('minuteLanguage', minuteLanguage, this.viewName)
      try {
        const fileName = `${this.procedureFullCode}-ata-${Dates.now().format('DD/MM/YYYY H:mm')}.docx`
        const response = await this.api.getStepProcedureDocument(
          this.procedureKey,
          fileName,
          this.viewName,
          minuteLanguage
        )
        console.log(response)
        this.setDirty(false)
        this.downloadFile(response, fileName)
      } catch (error) {
        this.setDirty(true)
        console.error(error)
      }
    },

    /**
     * Evaluation
     */
    openMyEvaluationMap() {
      if (this.myJuryInfo) {
        this.openEvaluationMap(this.myJuryInfo)
      }
    },

    async openEvaluationMap(jury) {
      console.log('openEvaluationMap jury:', jury)
      this.selectedJury = jury
      await this.getOpenedEvaluationMapInfo()
      this.activeModal = 'evaluations'
    },

    async saveEvaluations(evaluationMap, reason = null) {
      console.log('saveEvaluations =======', evaluationMap)
      console.log('saveEvaluations reason =======', reason)
      this.savingData = true

      const payload = {
        type: this.viewName,
        evaluations: [],
      }
      payload[`${this.viewName}_reason`] = reason

      for (let [candidateKey, evaluations] of Object.entries(evaluationMap)) {
        console.log(`candidateKey ${candidateKey}: eval`, evaluations)

        for (let [criteriumKey, value] of Object.entries(evaluations)) {
          if (value !== null && value >= 0 && value <= 100) {
            console.log('criteriumKey, value', criteriumKey, value)
            payload.evaluations.push({
              candidate: candidateKey,
              criterium: criteriumKey,
              value: value,
            })
          }
        }
      }

      try {
        const res = await this.api.setCandidatesEvaluation(this.procedureKey, this.selectedJury.jury_key, payload)
        console.log('setCandidatesEvaluation', res.evaluations)
        this.evaluationMaps[this.selectedJury.jury_key]['evaluations'] = res.evaluations
        this.evaluationMaps = res.jury
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.savingEvaluations'),
          type: 'is-danger',
          duration: 4000,
        })
        console.error(error)
      }

      this.savingData = false
    },

    async lockEvalMap(jurykey) {
      console.log('lockEvalMap', jurykey)

      try {
        let res = await this.api.sealJuryEvaluations(jurykey, this.viewName)
        console.log('lockEvalMap res', res)
        this.currentViewChecks = res.validations
        this.evaluationMaps = res.jury
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        console.error(error)
        this.$buefy.snackbar.open({
          message: 'Não foi possível lacrar o mapa de avaliação.',
          type: 'is-danger',
          duration: 4000,
        })
      }
    },

    async openEvalMap(jurykey) {
      console.log('openEvalMap', jurykey)

      try {
        let res = await this.api.undoSealJuryEvaluations(jurykey, this.viewName)
        console.log('openEvalMap res', res)
        this.currentViewChecks = res.validations
        this.evaluationMaps = res.jury
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        console.error(error)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.openingEvalMap'),
          type: 'is-danger',
          duration: 4000,
        })
      }
    },

    async getJuryInfo() {
      this.loading = true
      try {
        this.evaluationMaps = await this.api.getProcedureJuryInfo(this.procedureKey)
        console.log('getProcedureJuryInfo', this.evaluationMaps)
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
    /**
     * Get evaluations
     */
    async getOpenedEvaluationMapInfo() {
      //!!get this info if not saving data
      if (!this.savingData) {
        const juryKey = this.selectedJury.jury_key
        console.log('selected jury key', juryKey)
        let result = null
        try {
          result = await this.api.getJuryEvaluations(this.procedureKey, juryKey, this.viewName)
          console.log('getJuryEvaluations', result)
          this.evaluationMaps = result.jury
          this.evaluationMaps[juryKey]['evaluations'] = result.evaluations
        } catch (error) {
          this.$buefy.snackbar.open({
            message: this.$t('errorOccurred.gettingEvaluations'),
            type: 'is-danger',
            duration: 4000,
          })
          console.error(error)
        }
      }
    },

    async runSeriation() {
      console.log('Running seriation ----')
      try {
        const res = await this.api.runSeriation(this.procedureKey, this.viewName)
        console.log('Running seriation res ----', res)
        this.currentViewChecks = res.checks
        this.seriationResults = res.positions
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        console.error(error)
      }
    },
    async undoSeriation() {
      console.log('Cancel seriation ----', this.viewName)
      try {
        const res = await this.api.cancelSeriation(this.procedureKey, this.viewName)
        console.log('Cancel seriation res ----', res)
        this.currentViewChecks = res.checks
        this.validations = res.procedure.validations
        this.procedure.notifications = res.procedure.notifications
        this.seriationResults = []
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        console.error(error)
      }
    },
    async closeSeriation() {
      console.log('Close seriation ----', this.viewName)
      try {
        const res = await this.api.closeSeriation(this.procedureKey, this.viewName)
        console.log('Close seriation res ----', res)
        this.currentViewChecks = res
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        console.error(error)
      }
    },

    async updatePresidents(president, presidentSubtitute) {
      this.setDirty(true)
      this.savingData = true
      try {
        const result = await this.api.updatePresidents(this.procedureKey, president, presidentSubtitute)
        console.log('updatePresidents', result)
        this.procedure.presidents = result.presidents
        this.validations = result.validations
      } catch (error) {
        this.setDirty(true)
        this.savingData = false
        return console.error(error)
      }
      this.setDirty(false)
      this.savingData = false
    },

    async updateManager(user, transferData = {}) {
      this.setDirty(true)
      this.savingData = true
      try {
        const result = await this.api.updateManager(this.procedureKey, user.key, transferData)
        this.procedure = result.manager
        this.validations = result.validations

        console.log(result)
      } catch (error) {
        this.setDirty(true)
        this.savingData = false
        return console.error(error)
      }

      this.setDirty(false)
      this.savingData = false

      if (!this.isManager && !this.isAdmin) {
        this.$router.push({ name: 'home' })
      } else {
        this.$router.go(0)
      }
    },

    async rejectCandidates(rejectList) {
      this.savingData = true

      try {
        console.log('candidates rejected: ', rejectList)
        const result = await this.api.rejectCandidates(
          this.procedureKey,
          this.viewName,
          rejectList.map(el => el.candidate_key)
        )
        this.seriationResults = result.seriation
        this.currentViewChecks = result.validations
        console.log(result)
        this.setDirty(false)
      } catch (error) {
        this.setDirty(true)
        console.error(error)
      }

      this.savingData = false
    },

    async setCandidatesInterview(candidate = null, candidates = null) {
      this.savingData = true
      this.loadingData.setCandidatesInterview = true

      let payload = null
      if (candidate) {
        console.log('candidate :>> ', candidate)
        payload = [
          {
            key: candidate.candidate_key,
            interview_location: candidate.interview_location,
            interview_datetime: Dates.formatToAPI(candidate.interview_datetime) || null,
          },
        ]
      } else {
        let currentViewCandidates
        if (candidates) {
          currentViewCandidates = candidates
        } else {
          currentViewCandidates = this.currentViewCandidates
        }
        payload = currentViewCandidates.map(el => ({
          key: el.candidate_key,
          interview_location: el.interview_location,
          interview_datetime: Dates.formatToAPI(el.interview_datetime) || null,
        }))
      }
      try {
        const result = await this.api.setCandidatesInterview(this.procedureKey, { candidates: payload })

        this.setProcedureValidations(result)
        this.setProcedureApplications(result.applications)
        console.log(result)
        this.setDirty(false)
        this.$buefy.snackbar.open({
          message: this.$t('dataSaved'),
          type: 'is-primary',
          duration: 4000,
        })
      } catch (error) {
        this.setDirty(true)
        console.error(error)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.settingInterviewInfo'),
          type: 'is-danger',
          duration: 4000,
        })
        console.error(error)
      }

      this.savingData = false
      setTimeout(() => {
        this.loadingData.setCandidatesInterview = false
      }, 750)
    },

    async getComplaints() {
      try {
        this.procedure.complaints = await this.api.getComplaints(this.procedureFullCode)
      } catch (error) {
        console.error(error)
      }
    },

    async addComplaintMessage(complaintKey, message) {
      console.log('complaintKey, message :>> ', complaintKey, message)
      this.savingData = true
      try {
        const res = await this.api.createComplaintMessage(complaintKey, message.description)
        console.log('createComplaintMessage :>> ', res)
        if (message.files.length > 0) {
          for (let file of message.files) {
            console.log('relateComplaintMessageFile :>> ', res.complaint_message.key, file.key)
            await this.api.relateComplaintMessageFile(res.complaint_message.key, file.key)
          }
        }

        this.setProcedureValidations(res.procedure)
        await this.getComplaints()

        if (this.selectedApplication) {
          this.selectedApplication.complaints = [
            this.procedure.complaints.find(el => el.key == res.complaint_message.complaint_key),
          ]
        }
      } catch (error) {
        console.error(error)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred.respondingToComplaint'),
          type: 'is-danger',
          duration: 4000,
        })
      }

      this.savingData = false
    },

    closeModal() {
      console.log('Close modal')
      if (this.selectedJuryMap && this.dirtyData) {
        console.log('has dirty data')
        this.$buefy.dialog.confirm({
          cancelText: 'Cancelar',
          confirmText: 'Confirmar',
          title: 'Dados não guardados',
          message: 'Há dados que não foram guardados. Tem a certeza que quer mudar de página?',
          onConfirm: async () => {
            this.$store.commit('setDirtyData', false)
            this.closeModal()
          },
          onCancel: () => {
            return
          },
        })
      } else {
        this.activeModal = null
        this.meetingGoalOverride = null
        this.meetingData = {
          key: null,
          goal: null,
          type: this.$store.state.showVirtualMeetings ? 'online' : 'in_person',
          date: null,
          hour: null,
          min: null,
          location: '',
          uc_meetings_id: this.$store.state.showVirtualMeetings ? 'proc123' : null,
        }
      }
    },

    async openModal(type) {
      if (type == 'jury_message' && !this.defaultTemplate.email) {
        await this.getSettingsData()
      }
      this.activeModal = type
    },

    closeRejectedReasonModal() {
      this.rejectedApplication = null
    },

    goBack() {
      this.$router.go(-1)
    },

    goToView(name) {
      console.log('===== GOING TO VIEW', name)
      this.viewOverride = null
      const url = `/manage/procedure/${this.procedureKey}/${name}`
      if (this.$router.currentRoute.path !== url) {
        this.$router.push({ path: url })
      }
    },
  },
}
</script>

<style>
button {
  outline: none;
}

.procedure .textarea:focus,
.procedure .input:focus,
.procedure .taginput .taginput-container.is-focusable:focus,
.procedure .datepicker .dropdown .input:focus,
.procedure .datepicker .dropdown-trigger .input[readonly]:focus,
.procedure button:focus,
.procedure select:focus {
  border-color: transparent;
  outline: solid 2px rgba(3, 164, 121, 0.7);
  outline-offset: 2px;
  box-shadow: none;
}

.input-r .input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.dropdown-trigger .control.has-icons-left .icon {
  top: 1px;
  height: 2.5em;
  width: 2.5em;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.dropdown-trigger .control.has-icons-left .input {
  padding-left: 2.5em;
}

.datepicker .dropdown .input,
.datepicker .dropdown-trigger .input[readonly] {
  background-color: #f5f5f5;
}

.textarea,
.input,
.taginput .taginput-container.is-focusable {
  min-height: 35px;
  background-color: #f5f5f5;
}

.datepicker .dropdown .input::placeholder,
.datepicker .dropdown-trigger .input[readonly]::placeholder {
  color: rgba(108, 105, 105, 0.8);
}

.textarea::placeholder,
.input::placeholder,
.taginput .taginput-container.is-focusable::placeholder {
  color: rgba(108, 105, 105, 0.8);
}

.b-numberinput .control .button {
  height: 38px;
  width: 38px;
}

input::placeholder {
  color: rgba(108, 105, 105, 0.8);
}

input::-ms-input-placeholder {
  color: rgba(108, 105, 105, 0.8);
}
.select:not(.is-multiple) {
  height: 2.75rem;
}
.select select {
  height: 2.75rem;
}
.datepicker-header .pagination-previous {
  width: 3rem;
  height: 2.75rem;
}
.datepicker-header .pagination-next {
  width: 3rem;
  height: 2.75rem;
}
.bg-saved {
  background-color: rgba(47, 199, 149, 0.25);
}

.editor-variable {
  @apply bg-primary bg-opacity-10 rounded-md px-1.5 py-0.5 text-teal-700 font-semibold;
}

.procedure .error .textarea,
.procedure .error .input,
.procedure .error .datepicker .dropdown .input,
.procedure .error .datepicker .dropdown-trigger .input[readonly],
.procedure .error select,
.procedure .error .multiselect {
  border-color: #f15f1fff !important;
  border-width: 1px !important;
  border-style: solid !important;
}
</style>

<i18n>
{
  "pt": {
    "send": "Enviar",
    "procedureWasBeenOpenToTheJury": "O procedimento foi aberto ao júri",
    "dataSaved": "Dados guardados",
    "status": "Estado",
    "publish": "Publicar",
    "files": "Ficheiros",
    "cancel": "Cancelar",
    "confirm": "Confirmar",
    "procedure": "Procedimento",
    "updates": "Publicações",
    "calendar": "Calendarização",
    "candidates": "Candidatos",
    "jury": "Júri",
    "manager": "Gestor/a",
    "complaints": "Audiência(s) de interessados",
    "metadata": "Metadados",
    "criteria": "Critérios",
    "seriationResults": "Resultado intercalar",
    "sendAndStartProcedure": "Abrir procedimento",
    "result": "Resultado",
    "wantToDeleteProc": "Tem a certeza que quer eliminar o procedimento?",
    "accessNotAllowed": "Acesso não autorizado",
    "hasConflitofInterestAccessNotAllowed": "Não declarou que não existem conflitos de interesse neste procedimento. Neste sentido, o acesso à área de avaliação não está autorizado.",
    "meetingGoals": {
      "jury": "Definição do júri",
      "criteria": "Definição de critérios",
      "admission": "Admissão de candidatos",
      "complaints": "Audiência(s) de interessados",
      "seriation-curriculum": "Avaliação curricular",
      "seriation-interview": "Entrevista",
      "publicHearing": "Audição pública"
    },
    "procedureStatus": {
      "label": "Estados do procedimento",
      "draft": "Rascunho",
      "jury": "Júri",
      "running": "Iniciado",
      "ended": "Audiência(s) de interessados",
      "closed": "Encerrado",
      "published": "Publicado"
    },
    "mustAcceptConflitOfInterest": "Tem que Aceitar declaração de inexistência de conflitos de interesse para poder aceder a avaliação. Deseja ir para a declaração?",
    "variables": {
      "name": "Nome do elemento do júri",
      "code": "Código do procedimento",
      "seats": "Número de vagas",
      "type": "Tipo do procedimento",
      "link": "Link para o concurso",
      "managerName": "Nome do secretariado",
      "managerEmail": "E-mail do secretariado",
      "projectName": "Nome do projeto",
      "profile": "Perfil do candidato",
      "scientificArea": "Área científica do projeto",
      "category": "Categoria profissional do concurso",
      "area": "Área do concurso",
      "closingApplications": "Data de fecho de candidaturas",
      "faculty": "Unidade orgânica do concurso"
    },
    "meetingTypes": {
      "online": "Reunião online",
      "in_person": "Reunião presencial"
    },
    "verifyMeetingLocation": "Verifique que a localização da entrevista está correta",
    "accept": "Aceitar",
    "notificationsSent": {
      "results": "Notificações de resultados enviadas",
      "published": "Notificação dos prazos de candidatura enviadas",
      "interviewNotification": "Convocatórias de entrevista enviadas",
      "calendar": "Notificações de atualização de calendário enviadas."
    },
    "errorOccurred": {
      "sendingResults": "Ocorreu um erro ao enviar os resultados.",
      "sendingPublished": "Ocorreu um erro ao enviar as notificações dos prazos.",
      "sendingInterviewNotification": "Ocorreu um erro ao enviar as convocatórias.",
      "sendingCalendarUpdate": "Ocorreu um erro ao enviar as notificações de atualização de calendário.",
      "userExist": {
        "title": "Utilizador já existe",
        "message": "O utilizador que tentou convidar já existe na Apply"
      },
      "codeExists": {
        "title": "Código já definido",
        "message": "Já existe um procedimento com o código que tentou definir. Por favor, indique outro para guardar as alterações."
      },
      "default": {
        "title": "Ocorreu um erro",
        "message": "Ocorreu um erro não esperado {errorKey}. Por favor, contacte a nossa equipa de suporte."
      },
      "savingProcedure": "Ocorreu um erro não esperado {errorKey} a guardar o procedimento. Por favor, contacte a nossa equipa de suporte.",
      "download": "Ocorreu um erro ao tentar fazer download.",
      "savingManagerNotes": "Ocorreu um erro ao guardar as notas.",
      "deletingManagerNotes": "Ocorreu um erro ao apagar as notas.",
      "deletingProcedure":"Ocorreu um erro ao tentar eliminar o procedimento.",
      "publishingProcedure": "Ocorreu um erro ao tentar publicar o procedimento.",
      "openingProcedure": "Ocorreu um erro ao tentar abrir o procedimento.",
      "endingProcedure": "Ocorreu um erro ao tentar terminar a fase avaliativa do procedimento.",
      "closingProcedure": "Ocorreu um erro ao tentar terminar o procedimento.",
      "savingRejectReason": "Ocorreu um erro ao guardar a fundamentação.",
      "setingCandidatesAdmission": "Ocorreu um erro ao guardar a admissão.",
      "deletingUpdate": "Ocorreu um erro ao apagar uma atualização",
      "gettingSeriation": "Ocorreu um erro ao obter informação da seriação.",
      "gettingStepInfo": "Ocorreu um erro ao obter informação do passo atual.",
      "startingMeeting": "Ocorreu um erro ao iniciar a reunião.",
      "endingMeeting": "Ocorreu um erro ao terminar a reunião.",
      "reopeningMeeting": "Ocorreu um erro ao reabrir a reunião.",
      "closingMeeting": "Ocorreu um erro ao fechar a reunião.",
      "settingPresences": "Ocorreu um erro ao marcar a presença.",
      "savingEvaluations": "Ocorreu um erro ao guardar as avaliações.",
      "openingEvalMap": "Não foi possível abrir o mapa de avaliação.",
      "gettingJuryInfo": "Ocorreu um erro ao obter as informações do júri.",
      "gettingEvaluations": "Ocorreu um erro ao obter as avaliações.",
      "settingInterviewInfo": "Ocorreu um erro ao guardar as datas e locais de entrevista.",
      "respondingToComplaint": "Ocorreu um erro ao guardar a resposta ao candidato."
    },
    "accountAdded": "A conta de {user} ({email}) foi adicionada.",
    "openMeeting": "Abrir reunião",
    "continueOpenMeeting": "Existe uma reuniao marcada para as {date}, continuar mesmo assim?",
    "procedureStatusChecks": {
      "metadata": "Metadados preenchidos.",
      "criteria": "Critérios de seleção e avaliação definidos.",
      "people": "Presidente e Vogais definidos.",
      "calendar": "Calendarização definida.",
      "update": "Edital publicado.",
      "interviewUpdate": "Ata Entrevista + LUOF publicada.",
      "curriculumUpdate": "Ata Admissão/exclusão + AC + LUOF publicada.",
      "publishEnded": "Fase de candidaturas terminada.",
      "curriculum": "Avaliação curricular lacrada.",
      "maxDateComplaints": "Data limite para Audiência(s) de interessados terminada.",
      "allSolved": "Todas as alegações respondidas.",
      "interview": "Entrevista a candidatos lacrada."
    }
  },
  "en": {
    "send": "Send",
    "procedureWasBeenOpenToTheJury": "Procedure is open to the jury",
    "dataSaved": "Data saved",
    "status": "Status",
    "publish": "Publish",
    "files": "Files",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "procedure": "Procedure",
    "updates": "Publications",
    "calendar": "Calendar",
    "candidates": "Candidates",
    "jury": "Jury",
    "manager": "Manager",
    "complaints": "Stakeholder Hearing",
    "metadata": "Metadata",
    "criteria": "Criteria",
    "seriationResults": "Interim results",
    "sendAndStartProcedure": "Send and Start Procedure",
    "accessNotAllowed": "Access not allowed",
    "hasConflitofInterestAccessNotAllowed": "You did not declare that you don't have conflits of interest in this procedure. For this reason, you cannot access any evaluation data.",
    "result": "Result",
    "wantToDeleteProc": "Are you sure you want to delete the procedure?",
    "meetingGoals": {
      "jury": "Jury Definition",
      "criteria": "Criteria Definition",
      "admission": "Candidate Admission",
      "complaints": "Stakeholder Hearing",
      "seriation-curriculum": "Curriculum Seriation",
      "seriation-interview": "Candidate Interviews",
      "publicHearing": "Public Hearing"
    },
    "procedureStatus": {
      "label": "Procedure status",
      "draft": "Draft",
      "jury": "Jury",
      "running": "Running",
      "ended": "Stakeholder Hearing",
      "closed": "Ended",
      "published": "Published"
    },
    "mustAcceptConflitOfInterest": "You must Accept the declaration of no conflict of interest in order to access the evaluation. Do you want to go to the declaration?",
    "variables": {
      "name": "Name of the jury member",
      "code": "Procedure code",
      "seats": "Number of openings",
      "type": "Type of procedure",
      "link": "Link to the procedure",
      "managerName": "Name of the secretariat",
      "managerEmail": "Secretariat e-mail",
      "projectName": "Project name",
      "profile": "Candidate profile",
      "scientificArea": "Scientific area of the project",
      "category": "Professional category of the procedure",
      "closingApplications": "Close date of the applications",
      "area": "Procedure area",
      "faculty": "Organic unit of the procedure"
    },
    "meetingTypes": {
      "online": "Online meeting",
      "in_person": "In-person meeting"
    },
    "verifyMeetingLocation": "Verify that the interview location is correct",
    "accept": "Accept",
    "notificationsSent": {
      "results": "Result notifications sent.",
      "published": "Notification of application deadlines sent.",
      "interviewNotification": "Notifications for interviews sent.",
      "calendar": "Calendar update notifications sent."
    },
    "errorOccurred": {
      "sendingResults": "An error occurred while sending the results.",
      "sendingPublished": "An error occurred while sending the deadline notifications.",
      "sendingInterviewNotification": "An error occurred while sending the interview notifications.",
      "sendingCalendarUpdate": "Ocorreu um erro ao enviar as notificações de atualização de calendário.",
      "userExist": {
        "title": "User already exists",
        "message": "The user you tried to invite already exists in Apply"
      },
      "default": {
        "title": "An error has occurred",
        "message": "An unexpected error occurred {errorKey}. Please contact our support team."
      },
      "savingProcedure": "An unexpected error occurred {errorKey} while trying to save the procedure. Please contact our support team.",
      "download": "An error occurred while trying to download the file.",
      "savingManagerNotes": "An error occurred while saving the notes.",
      "deletingManagerNotes": "An error occurred while deleting the notes.",
      "deletingProcedure":"An error occurred while trying to delete the procedure.",
      "publishingProcedure": "An error occurred while trying to publish the procedure.",
      "openingProcedure": "An error occurred while trying to open the procedure.",
      "endingProcedure": "An error occurred while trying to finish the evaluation phase of the procedure.",
      "closingProcedure": "An error occurred while trying to close the procedure.",
      "savingRejectReason": "An error occurred when saving the rejection reason.",
      "setingCandidatesAdmission": "An error occurred when saving the candidate admission.",
      "deletingUpdate": "An error occurred while trying to delete a procedure update.",
      "gettingSeriation": "An error occurred while obtaining seriation data",
      "gettingStepInfo": "An error occurred when obtaining information for the current step.",
      "startingMeeting": "An error occurred when starting the meeting.",
      "endingMeeting": "An error occurred at the end of the meeting.",
      "reopeningMeeting": "An error occurred when reopening the meeting.",
      "closingMeeting": "An error occurred while closing the meeting.",
      "settingPresences": "An error occurred when marking the presence.",
      "savingEvaluations": "An error occurred when saving evaluations.",
      "openingEvalMap": "Unable to open evaluation map.",
      "gettingJuryInfo": "Ocorreu um erro ao obter as informações do júri.",
      "gettingEvaluations": "An error occurred while getting the evaluations.",
      "settingInterviewInfo": "An error occurred while setting the candidates interviews.",
      "respondingToComplaint": "An error occurred while responding to the candidate."
    },
    "accountAdded": "The account of {user} ({email}) was added.",
    "openMeeting": "Open meeting",
    "continueOpenMeeting": "There is a meeting scheduled at {date}, continue anyway?",
    "procedureStatusChecks": {
      "metadata": "Completed metadata.",
      "criteria": "Defined evaluation and selection criteria.",
      "people": "Chairman and Members defined.",
      "calendar": "Calendar defined.",
      "update": "Edital published.",
      "interviewUpdate": "Minute Interview + LUOF published.",
      "curriculumUpdate": "Minute Admission/exclusion + AC + LUOF published.",
      "publishEnded": "Application phase ended.",
      "curriculum": "Curriculum evaluation sealed. ",
      "maxDateComplaints": "Stakeholder Hearing deadline has expired.",
      "allSolved": "All allegations answered.",
      "interview": "Interview phase ended."
    }
  }
}
</i18n>
